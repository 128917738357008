import { GraphQLClient, RequestOptions } from "graphql-request";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
type GraphQLClientRequestHeaders = RequestOptions["requestHeaders"];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**  A big integer, serialized as a string  */
  BigInteger: { input: any; output: any };
  /**  A unitless rational number, serialized as an array of numerator and denominator  */
  Fraction: { input: any; output: any };
  /**  A set of bytes, serialized as a hex string  */
  HexBytes: { input: any; output: any };
  /**  An arbitrary block of JSON  */
  JSON: { input: any; output: any };
  /**
   * A very compact version of ticks, useful for fetching full candle data
   * Rendered as an array of values, as described below:
   *   - Unix timestamp of the start of the tick
   *   - The price when the tick opened, as a float, in units of "assetA per assetB"
   *   - The price when the tick closed, as a float, in units of "assetA per assetB"
   *   - The highest price traded in the interval, as a float, in units of "assetA per assetB"
   *   - The lowest price traded in the interval, as a float, in units of "assetA per assetB"
   *   - The number of orders created in this interval; currently not working
   *   - The number of orders closed (executed or cancelled) in this interval; currently not working
   *   - The total volume traded, in the specified unit; The sume of volumeA and volumeB
   *   - The total value of assets deposited, in the specified unit; The sum of depositA and depositB
   *   - The total value of assets withdrawn, in the specified unit; The sum of withdrawA and withdrawB
   *   - The cardano transaction fees paid in order to execute the scoops; currently not working
   *   - The protocol fees collected from each scoop in this interval
   *   - The total LP fees collected by liquidity providers in this interval
   */
  TickCompact: { input: any; output: any };
};

/**
 * An asset
 *
 * An asset
 *
 * An asset on the Cardano network
 */
export type Asset = {
  __typename?: "Asset";
  /**  The assetName portion of the AssetID, as a UTF-8 string  */
  assetName: Scalars["String"]["output"];
  /**  The assetName portion of the AssetID, as a hex string  */
  assetNameHex: Scalars["String"]["output"];
  /**  The first time we saw metadata for this token  */
  dateListed?: Maybe<DateTime>;
  /**  The number of decimals to use when displaying the token; defaults to 0  */
  decimals: Scalars["Int"]["output"];
  /**  A short description of the token and its purpose  */
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  /**  A URL for the token logo  */
  logo?: Maybe<Scalars["String"]["output"]>;
  /**  The original component pieces of metadata, if aggregated from multiple places  */
  metadata: Array<TokenMetadata>;
  /**  The human readable name derived from the token metadata  */
  name: Scalars["String"]["output"];
  /**  The policy ID portion of the AssetID  */
  policyId: Scalars["String"]["output"];
  /**  A short ticker for the asset, derived from the token metadata  */
  ticker?: Maybe<Scalars["String"]["output"]>;
};

/**  A quantity and a unit  */
export type AssetAmount = {
  __typename?: "AssetAmount";
  asset: Asset;
  quantity: Scalars["BigInteger"]["output"];
};

/**  A ratio between two assets  */
export type AssetRatio = {
  __typename?: "AssetRatio";
  denominator: AssetAmount;
  numerator: AssetAmount;
  ratio: Scalars["Float"]["output"];
};

export type AssetsQuery = {
  __typename?: "AssetsQuery";
  /**  Get a single asset by its ID  */
  byId: Asset;
  /**  Get multiple assets by their IDs  */
  byIds: Array<Asset>;
  /**  Search for assets by name, ticker, or AssetID  */
  search: Array<Asset>;
};

export type AssetsQueryByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type AssetsQueryByIdsArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type AssetsQuerySearchArgs = {
  term: Scalars["String"]["input"];
};

/**  A blueprint for a specific protocol, as a collection of validators  */
export type Blueprint = {
  __typename?: "Blueprint";
  /**  The validators that make up this version of the protocol  */
  validators: Array<Validator>;
};

/**  Token metadata sourced from the Cardano Token Registry  */
export type CardanoTokenRegistry = TokenMetadata & {
  __typename?: "CardanoTokenRegistry";
  /**  The number of decimals to use when displaying the token  */
  decimals: Scalars["Int"]["output"];
  /**  A short description of the token and its purpose  */
  description: Scalars["String"]["output"];
  /**  The first time we saw metadata for this token  */
  listedAt: DateTime;
  /**  A URL for the token logo  */
  logo: Scalars["String"]["output"];
  /**  The human readable name  */
  name: Scalars["String"]["output"];
  /**  The token policy script  */
  policy: Scalars["String"]["output"];
  /**  A short ticker for this token  */
  ticker: Scalars["String"]["output"];
  /**  The last time we saw metadata for this token  */
  updatedAt: DateTime;
  /**  A URL for the project behind this token  */
  url: Scalars["String"]["output"];
};

/**  A specific moment in time  */
export type DateTime = {
  __typename?: "DateTime";
  format: Scalars["String"]["output"];
  slot: Scalars["Int"]["output"];
};

/**  A specific moment in time  */
export type DateTimeFormatArgs = {
  layout?: InputMaybe<Scalars["String"]["input"]>;
};

/**  A delta between two snapshots  */
export type Delta = {
  __typename?: "Delta";
  /**  The absolute differences between the two snapshots  */
  difference: Stats;
  /**  The comparand of the delta  */
  end: DateTime;
  /**  The percent change between the two snapshots  */
  percent: StatsPercent;
  /**  The baseline of the delta  */
  start: DateTime;
};

/** A request to deposit assets into the pool, in return for some LP tokens */
export type Deposit = {
  __typename?: "Deposit";
  /** The estimated number of LP tokens, given the current pool state */
  estimated: AssetAmount;
  /** The amount of assets being deposited */
  offer: Array<AssetAmount>;
  /** The actual amount of LP tokens received as a result of the deposit, if its executed */
  received?: Maybe<AssetAmount>;
};

export type Destination = {
  __typename?: "Destination";
  address: Scalars["String"]["output"];
  datumHash: Scalars["String"]["output"];
};

export type Fees = {
  __typename?: "Fees";
  /** The amount of fees (of asset A in the pool) that this position has collectively earned since deposit */
  assetA: AssetAmount;
  /** The amount of fees (of asset B in the pool) that this position has collectively earned since deposit */
  assetB: AssetAmount;
};

/**  The interval of tick to query; Currently only Hourly is supported  */
export enum Interval {
  All = "All",
  Daily = "Daily",
  Hourly = "Hourly",
  Minute = "Minute",
  Monthly = "Monthly",
  Raw = "Raw",
}

export type Liquidity = {
  __typename?: "Liquidity";
  /** The fees (in either asset) that have been collectively earned since these LP tokens were minted */
  fees: Fees;
  /** The pool that this liquidity is associated with */
  pool: Pool;
  /** The quantity of LP tokens that this liquidity position consists of */
  quantity: AssetAmount;
};

/** Token metadata published on-chain under the metadata label "20"  */
export type OnChainLabel20 = TokenMetadata & {
  __typename?: "OnChainLabel20";
  /**  The number of decimals to use when displaying the token  */
  decimals: Scalars["Int"]["output"];
  /**  A short description of the token and its purpose  */
  desc: Scalars["String"]["output"];
  /**  A URL for the token logo  */
  icon: Scalars["String"]["output"];
  /**  The first time we saw metadata for this token  */
  listedAt: DateTime;
  /**  The raw JSON from label 20  */
  properties: Scalars["JSON"]["output"];
  /**  A short ticker for this token  */
  ticker: Scalars["String"]["output"];
  /**  The last time we saw metadata for this token  */
  updatedAt: DateTime;
  /**  A URL for the project behind this token  */
  url: Scalars["String"]["output"];
  /**  The version of the CIP-20 standard to use  */
  version: Scalars["String"]["output"];
};

/** Token metadata published on-chain under the metadata label "721"  */
export type OnChainLabel721 = TokenMetadata & {
  __typename?: "OnChainLabel721";
  /**  A short description of the token and its purpose  */
  description: Scalars["String"]["output"];
  /**  A URL for the token logo  */
  image: Scalars["String"]["output"];
  /**  The first time we saw metadata for this token  */
  listedAt: DateTime;
  /**  The type of media attached to this token, such as image or video  */
  mediaType: Scalars["String"]["output"];
  /**  The human readable name  */
  name: Scalars["String"]["output"];
  /**  The raw JSON from label 721  */
  properties: Scalars["JSON"]["output"];
  /**  The last time we saw metadata for this token  */
  updatedAt: DateTime;
};

/** An order placed on the DEX; Either a swap, deposit, or withdrawal */
export type Order = {
  __typename?: "Order";
  /** When the order was initially listed on-chain */
  createdAt: DateTime;
  /** Details specific to this order type */
  details: OrderDetails;
  /** The order ID; in particular, `txHash#outputIndex` */
  id: Scalars["ID"]["output"];
  /** The outcome of the order: whether it was processed or cancelled */
  outcome?: Maybe<Outcome>;
  /** Optionally, the pool identifier that this order *must* execute against */
  pool?: Maybe<Pool>;
  /** The time at which this order was spent, if it's been spent */
  spentAt?: Maybe<DateTime>;
  /** The transaction hash of the transaction that spent this order */
  spentTxHash?: Maybe<Scalars["String"]["output"]>;
  /** The sundae protocol version this order was placed and executed against */
  version: Version;
};

export type OrderDetails = Deposit | Swap | Withdraw;

export type Orders = {
  __typename?: "Orders";
  open: Array<Order>;
  recent: Array<Order>;
};

export enum Outcome {
  Cancelled = "Cancelled",
  Executed = "Executed",
}

/**  A SundaeSwap liquidity pool  */
export type Pool = {
  __typename?: "Pool";
  /**  The first asset of the pool  */
  assetA: Asset;
  /**  The second asset of the pool  */
  assetB: Asset;
  /**  The Asset representing the LP token the pool issues for deposits  */
  assetLP: Asset;
  /**  The current statistics of the pool  */
  current: Stats;
  /**  The time that fees reach their final value  */
  feesFinalized: DateTime;
  /**  The final fee, as a fraction, that the pool converges to by feesFinalized  */
  finalFee: Scalars["Fraction"]["output"];
  /**  The pool identifier  */
  id: Scalars["ID"]["output"];
  /**  The time that trading against the pool should be allowed  */
  marketOpen: DateTime;
  /**  The fee, as a fraction, at the moment the pool opens for trading  */
  openingFee: Scalars["Fraction"]["output"];
  orders: Orders;
  /**  Candlestick data for the pool  */
  ticks?: Maybe<Ticks>;
  /**  The protocol version this pool is tied to  */
  version: Version;
};

/**  A SundaeSwap liquidity pool  */
export type PoolTicksArgs = {
  end: Scalars["String"]["input"];
  interval: Interval;
  start: Scalars["String"]["input"];
};

/**  Different ways to query for different pools  */
export type PoolsQuery = {
  __typename?: "PoolsQuery";
  /**  Find all pools that trade a specific asset by Asset ID  */
  byAsset: Array<Pool>;
  /**  Find all pools that trade any one of a number of assets  */
  byAssets: Array<Pool>;
  /**  Fetch a single pool by its Identifier  */
  byId: Pool;
  /**  Fetch many pools by their IDs  */
  byIds: Array<Pool>;
  /**  Fetch the pool associated with an LP token; Will return null if the asset isn't an LP token  */
  byLPToken?: Maybe<Pool>;
  /**  Fetch the pools associated with any LP tokens in the list; If one of the assetIDs isn't an LP token, it will be filtered  */
  byLPTokens: Array<Pool>;
  /**  Find all pools that trade a specific pair of assets  */
  byPair: Array<Pool>;
  /** Find the 50 most "popular" pools, by TVL  */
  popular: Array<Pool>;
  /**  Search for pools using fuzzy string matching  */
  search: Array<Pool>;
};

/**  Different ways to query for different pools  */
export type PoolsQueryByAssetArgs = {
  asset: Scalars["ID"]["input"];
};

/**  Different ways to query for different pools  */
export type PoolsQueryByAssetsArgs = {
  assets: Array<Scalars["ID"]["input"]>;
};

/**  Different ways to query for different pools  */
export type PoolsQueryByIdArgs = {
  id: Scalars["ID"]["input"];
};

/**  Different ways to query for different pools  */
export type PoolsQueryByIdsArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

/**  Different ways to query for different pools  */
export type PoolsQueryByLpTokenArgs = {
  assetId: Scalars["ID"]["input"];
};

/**  Different ways to query for different pools  */
export type PoolsQueryByLpTokensArgs = {
  assetIds: Array<Scalars["ID"]["input"]>;
};

/**  Different ways to query for different pools  */
export type PoolsQueryByPairArgs = {
  assetA: Scalars["ID"]["input"];
  assetB: Scalars["ID"]["input"];
};

/**  Different ways to query for different pools  */
export type PoolsQuerySearchArgs = {
  term: Scalars["String"]["input"];
};

export type Portfolio = {
  __typename?: "Portfolio";
  /** The address of the portfolio whose data is surfaced */
  id: Scalars["ID"]["output"];
  /** Any liquidity owned by this portfolio, either in the wallet directly or in some other indexed contract like yield farming */
  liquidity: Array<Liquidity>;
  /** A history of orders placed by the user */
  orders: Array<Order>;
};

/**  A specific instance or version of the Sundae protocol  */
export type Protocol = {
  __typename?: "Protocol";
  /**  The blueprint, describing all scripts that make up this version of the protocol  */
  blueprint: Blueprint;
  /**  The environment this protocol should be deployed to, for avoidance of doubt  */
  environment: Scalars["String"]["output"];
  /**  References to the above scripts published on-chain  */
  references: Array<ScriptReference>;
  /**  The version label of the protocol, such as V1, V3, V3-Stable, etc.  */
  version: Version;
};

/**  A temporary type for global protocol stats  */
export type ProtocolStats = {
  __typename?: "ProtocolStats";
  /**  The number of orders filled in the last 24 hours  */
  filledOrders: Scalars["Int"]["output"];
  /**  The number of orders opened in the last 24 hours  */
  openedOrders: Scalars["Int"]["output"];
  /**  The number of pools in the protocol  */
  poolCount: Scalars["Int"]["output"];
  /**  The total protocol revenue collected  */
  protocolRevenue: AssetAmount;
  /**  The timestamp as of when the stats are valid  */
  timestamp: DateTime;
  /**  The total value locked in the protocol  */
  tvl: AssetAmount;
  /**  The total liquidity fees collected by the protocol in the last 24 hours  */
  volume: AssetAmount;
};

export type Query = {
  __typename?: "Query";
  /**  Queries for specific assets  */
  assets: AssetsQuery;
  /**  Recently opened / filled orders  */
  orders: Array<Order>;
  /**  Fetch information about any pools in this environment  */
  pools: PoolsQuery;
  /**  The portfolio details for a specific wallet address  */
  portfolio: Portfolio;
  /**  Fetch information about each protocol deployed in this environment  */
  protocols: Array<Protocol>;
  stats: ProtocolStats;
  /**  Portfolio-specific details about a given UTXO; Note: we currently don't support querying *arbitrary* transaction outputs, only those with LP tokens on them  */
  transactionOutput: Array<TxOut>;
};

export type QueryOrdersArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPortfolioArgs = {
  address: Scalars["String"]["input"];
};

export type QueryTransactionOutputArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

/**  A reference to a specific on-chain script  */
export type ScriptReference = {
  __typename?: "ScriptReference";
  /**  The title of the validator being referenced  */
  key: Scalars["String"]["output"];
  /**  The reference to the output that has this script as a script ref  */
  txIn: TxIn;
};

/**  Time-dependent snapshot statistics  */
export type Stats = {
  __typename?: "Stats";
  /**  The time the snapshot is applicable to  */
  asOf: DateTime;
  /**  The price of the pool calculated in the given unit  */
  price: AssetRatio;
  /**  The total protocol fees currently collected and not paid out by this pool  */
  protocolFees: AssetAmount;
  /**  The reserves of assetA in the pool  */
  quantityA: AssetAmount;
  /**  The reserves of assetB in the pool  */
  quantityB: AssetAmount;
  /**  The total circulating supply of LP tokens  */
  quantityLP: AssetAmount;
  /**  The total value locked in the pool, calculated in the given unit  */
  tvl: AssetAmount;
};

/**  Time-dependent snapshot statistics  */
export type StatsPriceArgs = {
  unit?: InputMaybe<Unit>;
};

/**  Time-dependent snapshot statistics  */
export type StatsTvlArgs = {
  unit?: InputMaybe<Unit>;
};

/**  Time-dependent snapshot statistics, as a percentage of some value, such as a percentage change  */
export type StatsPercent = {
  __typename?: "StatsPercent";
  /**  The percent change of the price, calculated in the given unit  */
  price: Scalars["Float"]["output"];
  /**  The percentage change of the reserves in assetA in the pool  */
  quantityA: Scalars["Float"]["output"];
  /**  The percentage change of the reserves in assetB in the pool  */
  quantityB: Scalars["Float"]["output"];
  /**  The percentage change in the circulating supply of LP tokens  */
  quantityLP: Scalars["Float"]["output"];
  /**  The percent change of the total value locked in the pool, calculated in the given unit  */
  tvl: Scalars["Float"]["output"];
};

/**  Time-dependent snapshot statistics, as a percentage of some value, such as a percentage change  */
export type StatsPercentPriceArgs = {
  unit?: InputMaybe<Unit>;
};

/**  Time-dependent snapshot statistics, as a percentage of some value, such as a percentage change  */
export type StatsPercentTvlArgs = {
  unit?: InputMaybe<Unit>;
};

/** A request to swap one asset for another */
export type Swap = {
  __typename?: "Swap";
  /** The estimated outcome of the swap, given the current pool state */
  estimated: AssetAmount;
  /** The minimum amount of tokens that must be received */
  minimum: AssetAmount;
  /** The portion of the assets on the UTXO being offered for the swap */
  offer: AssetAmount;
  /** The amount of tokens actually received from the swap */
  received?: Maybe<AssetAmount>;
};

/**  A rich tick object, mostly useful for very small intervals, or for learning the shape of the data  */
export type Tick = {
  __typename?: "Tick";
  /**  The price as of the end window  */
  close: AssetRatio;
  /**  The total value of assets deposited, in the specified unit; The sum of depositA and depositB  */
  deposit: AssetAmount;
  /**  The end of the tick interval  */
  end: DateTime;
  /**  The highest price traded in the interval  */
  high: AssetRatio;
  /**  The lowest price traded in the interval  */
  low: AssetRatio;
  /**  The total LP fees collected by liquidity providers in this interval  */
  lpFees: AssetAmount;
  /**  The price as of the start window  */
  open: AssetRatio;
  /**  The number of orders closed (executed or cancelled) in this interval; currently not working  */
  ordersClosed: Scalars["Int"]["output"];
  /**  The number of orders created in this interval; currently not working  */
  ordersOpened: Scalars["Int"]["output"];
  /**  The protocol fees collected from each scoop in this interval  */
  protocolFees: AssetAmount;
  /**  The start of the tick interval  */
  start: DateTime;
  /**  The cardano transaction fees paid in order to execute the scoops; currently not working  */
  txFees: AssetAmount;
  /**  The total volume traded, in the specified unit; The sum of volumeA and volumeB  */
  volume: AssetAmount;
  /**  The total value of assets withdrawn, in the specified unit; The sum of withdrawA and withdrawB  */
  withdraw: AssetAmount;
};

/**  A rich tick object, mostly useful for very small intervals, or for learning the shape of the data  */
export type TickDepositArgs = {
  unit: Unit;
};

/**  A rich tick object, mostly useful for very small intervals, or for learning the shape of the data  */
export type TickLpFeesArgs = {
  unit: Unit;
};

/**  A rich tick object, mostly useful for very small intervals, or for learning the shape of the data  */
export type TickVolumeArgs = {
  unit: Unit;
};

/**  A rich tick object, mostly useful for very small intervals, or for learning the shape of the data  */
export type TickWithdrawArgs = {
  unit: Unit;
};

/**  The candlestick data associated with a pool  */
export type Ticks = {
  __typename?: "Ticks";
  /**  The candlestick data in a compact format, useful for most purposes  */
  compact: Array<Scalars["TickCompact"]["output"]>;
  /**  A rich description of candlestick data, useful for very small intervals, or for learning the shape of the data  */
  rich: Array<Tick>;
};

/**  Token metadata, providing additional human-consumable information about a specific asset  */
export type TokenMetadata = {
  listedAt: DateTime;
  updatedAt: DateTime;
};

/**  A reference to a specific on-chain transaction output  */
export type TxIn = {
  __typename?: "TxIn";
  /**  The transaction hash  */
  hash: Scalars["HexBytes"]["output"];
  /**  The index in the outputs array for the tx output  */
  index: Scalars["Int"]["output"];
};

/**  Details about a specific transaction output  */
export type TxOut = {
  __typename?: "TxOut";
  /** The fees earned by this UTXO specifically, if it holds LP tokens */
  fees: Array<Liquidity>;
  /** The TxIn ("txHash#idx") of this UTXO */
  id: Scalars["ID"]["output"];
  /** The transaction this UTXO was spent in */
  spentTxHash?: Maybe<Scalars["String"]["output"]>;
};

/**  The unit to return an asset amount in; Natural chooses an opinionated 'best' option  */
export enum Unit {
  AssetA = "AssetA",
  AssetB = "AssetB",
  Lovelace = "Lovelace",
  Natural = "Natural",
  Usd = "USD",
}

/**  A specific validator contract on Cardano  */
export type Validator = {
  __typename?: "Validator";
  /**  The compiled code  */
  compiledCode: Scalars["HexBytes"]["output"];
  /**  The hash of the script, useful for constructing the payment key  */
  hash: Scalars["HexBytes"]["output"];
  /**  The title of the validator  */
  title: Scalars["String"]["output"];
};

/**  The protocol version tag we're working with  */
export enum Version {
  V1 = "V1",
  V3 = "V3",
}

/** A request to burn LP tokens, in return for a share of the pool assets */
export type Withdraw = {
  __typename?: "Withdraw";
  /** The amount of LP tokens being withdrawn */
  amount: AssetAmount;
  /** The estimated amount of tokens that will be received, given the current pool state */
  estimated: Array<AssetAmount>;
  /** The actual amount of tokens received, if the withdrawal has executed */
  received: Array<AssetAmount>;
};

export type AssetMetadataFragment = {
  __typename?: "Asset";
  id: string;
  assetName: string;
  ticker?: string | null;
  decimals: number;
  logo?: string | null;
};

export type GetAssetsQueryVariables = Exact<{
  ids: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type GetAssetsQuery = {
  __typename?: "Query";
  assets: {
    __typename?: "AssetsQuery";
    byIds: Array<{
      __typename?: "Asset";
      id: string;
      assetName: string;
      ticker?: string | null;
      decimals: number;
      logo?: string | null;
    }>;
  };
};

export type GetAssetQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetAssetQuery = {
  __typename?: "Query";
  assets: {
    __typename?: "AssetsQuery";
    byId: {
      __typename?: "Asset";
      id: string;
      assetName: string;
      ticker?: string | null;
      decimals: number;
      logo?: string | null;
    };
  };
};

export const AssetMetadataFragmentDoc = gql`
  fragment AssetMetadata on Asset {
    id
    assetName
    ticker
    decimals
    logo
  }
`;
export const GetAssetsDocument = gql`
  query getAssets($ids: [ID!]!) {
    assets {
      byIds(ids: $ids) {
        ...AssetMetadata
      }
    }
  }
  ${AssetMetadataFragmentDoc}
`;
export const GetAssetDocument = gql`
  query getAsset($id: ID!) {
    assets {
      byId(id: $id) {
        ...AssetMetadata
      }
    }
  }
  ${AssetMetadataFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
  _variables,
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    getAssets(
      variables: GetAssetsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAssetsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAssetsQuery>(GetAssetsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getAssets",
        "query",
        variables,
      );
    },
    getAsset(
      variables: GetAssetQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAssetQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAssetQuery>(GetAssetDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getAsset",
        "query",
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
