import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useI18N, useTheme } from "@sundae/react-hooks";
import { Balance, Header as SundaeHeader, Text } from "@sundae/ui-toolkit";
import { FC } from "react";

import { useAdaBalance } from "../../hooks/useAdaBalance";
import { useIsWalletConnected } from "../../hooks/useIsWalletConnected";
import AvailableADABalanceTooltip from "../AvailableADABalanceTooltip";
import WalletButton from "../WalletButton";
import Link from "../Link";

const Header: FC = () => {
  const { currentLocale, t } = useI18N("header");
  const isWalletConnected = useIsWalletConnected();
  const { theme, toggleTheme } = useTheme();
  const { availableAdaBalance, loaded } = useAdaBalance();

  return (
    <SundaeHeader
      allowTracking={false}
      countersApiOptions={{ app: "sundaeswap", company: "sundaelabs" }}
      isConnected={isWalletConnected}
      openOffCanvasLabel={t("header.menu.openOffCanvasLabel")}
      closeOffCanvasLabel={t("header.menu.closeOffCanvasLabel")}
      navigationItems={[
        {
          title: "Home",
          renderLink: (children) => <Link to="/">{children}</Link>,
        },
      ]}
      renderLogo={({ SundaeSwapLogo }) => (
        <Link
          className="flex items-center"
          title={"GummiWorm Demo"}
          hoverable={false}
          to={"/"}
          variant="info"
        >
          <SundaeSwapLogo className="h-8 w-8" />
        </Link>
      )}
      renderWalletButton={() => (
        <div className="flex items-center space-x-4">
          {isWalletConnected && (
            <Text
              className="flex items-center gap-1"
              tag="span"
              size="sm"
              variant="muted"
            >
              {loaded ? (
                <>{t("balance", { ns: "glossary", context: "available" })}:</>
              ) : (
                <>
                  {t("balance", { ns: "glossary", context: "loading" })}
                  <ArrowPathIcon className="h-4 w-4 animate-spin" />
                </>
              )}
              <AvailableADABalanceTooltip>
                {loaded && availableAdaBalance?.quantity && (
                  <Balance
                    locale={currentLocale}
                    privacy={false}
                    size="sm"
                    weight="bold"
                    value={availableAdaBalance.quantity}
                  />
                )}
              </AvailableADABalanceTooltip>
            </Text>
          )}
          <WalletButton />
        </div>
      )}
      showThemeToggle
      theme={theme}
      toggleTheme={toggleTheme}
    />
  );
};

export default Header;
