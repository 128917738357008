import { useI18N } from "@sundae/react-hooks";
import {
  Balance,
  Button,
  Confetti,
  Dialog,
  Input,
  Slider,
  TabsV3,
  Text,
} from "@sundae/ui-toolkit";
import { SundaeUtils } from "@sundaeswap/core/utilities";
import { useMutation } from "@tanstack/react-query";
import { Assets } from "lucid-cardano";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { QrCodeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import QRCode from "react-qr-code";
import { QrReader } from "react-qr-reader";
import { useGummiBalance } from "../../../hooks/useGummiBalance";
import { Actions, useAppContext } from "../../../stores";
import LoadingComponent from "../../LoadingComponent";

const SendReceiveDialog: React.FC = () => {
  const { t } = useI18N("deposit");
  const [sent, setSent] = useState(false);
  const [useQrReader, setUseQrReader] = useState(false);
  const [fireConfetti, setFireConfetti] = useState(false);
  const [sendAddress, setSendAddress] = useState<string>();
  const { balance, isLoading, gummi } = useGummiBalance();
  const {
    state: {
      gummiWallet,
      dialogs: { sendReceiveOpen },
      send: { current },
    },
    dispatch,
  } = useAppContext();

  const currentAssetInWallet = useMemo(() => {
    if (!current) {
      return "0";
    }

    const resolvedAssetId = SundaeUtils.isAdaAsset(current.metadata)
      ? "lovelace"
      : current.metadata.assetId;

    return balance?.[resolvedAssetId]?.toString() ?? "0";
  }, [balance, current?.metadata?.assetId]);

  const totalAmount = useMemo(() => {
    if (isLoading || !current || !currentAssetInWallet) {
      return 0;
    }

    return Number(currentAssetInWallet);
  }, [isLoading, currentAssetInWallet]);

  const handleClose = useCallback(() => {
    setSent(false);
    setUseQrReader(false);
    setSendAddress(undefined);
    Actions.setSendAsset(undefined, dispatch);
    Actions.setSendReceiveOpen(false, dispatch);
  }, [dispatch]);

  const name = useMemo(() => {
    return (
      current?.metadata?.ticker ||
      current?.metadata?.name ||
      Buffer.from(
        current?.metadata?.assetNameHex ??
          current?.metadata?.assetId?.slice(56) ??
          "",
        "hex",
      ).toString("utf-8")
    );
  }, [current?.metadata]);

  const { mutateAsync: handleSend } = useMutation(
    ["sendAddress", sendAddress, current?.metadata.assetId],
    async () => {
      if (!current || !sendAddress || !gummiWallet) {
        return;
      }

      const payment: Assets = {
        lovelace: 0n,
        [current.metadata.assetId.replace(".", "")]: current.amount,
      };

      const tx = await gummiWallet
        .newTx()
        .payToAddress(sendAddress, payment)
        .complete();

      console.log(tx.toString());
      const signed = await tx.sign().complete();

      try {
        await signed.submit();
        setSent(true);
      } catch (e) {
        console.log(e);
      }
    },
    {
      onError(error) {
        console.log(error);
      },
    },
  );

  useEffect(() => {
    if (sent) {
      setTimeout(() => {
        setFireConfetti(true);
      }, 100);
    }
  }, [sent]);

  if (!current || !gummi) {
    return null;
  }

  return (
    <Dialog
      cardContainerClassName="relative"
      open={sendReceiveOpen}
      closeDialog={handleClose}
      description={t("description", { assetName: name })}
      title={t("title", { assetName: name })}
    >
      <TabsV3
        defaultValue="send"
        data={[
          {
            id: "send",
            trigger: <Text tag="p">Send</Text>,
            content: (
              <div className="flex flex-col items-center justify-center gap-8">
                {sent ? (
                  <>
                    <Confetti
                      particleCount={150}
                      className="!absolute bottom-0 left-0"
                      fire={fireConfetti}
                      spread={55}
                      origin={{ y: 1.05 }}
                    />
                    <div className="relative z-10 flex flex-col items-center justify-center gap-4 py-8">
                      <Text size="3xl" tag="h2" weight="bold">
                        Sent!
                      </Text>
                      <Button variant="primary" onClick={handleClose}>
                        Close
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    {isLoading && <LoadingComponent />}
                    {totalAmount && (
                      <>
                        <Slider
                          value={[Number(current.amount.toString())]}
                          onValueChange={(value) => {
                            Actions.setSendAsset(
                              current?.withAmount(value[0]),
                              dispatch,
                            );
                          }}
                          max={totalAmount}
                        />
                        <Balance size="4xl" weight="bold" value={current} />
                        {useQrReader && (
                          <>
                            <XMarkIcon
                              className="ml-auto h-6 w-6"
                              onClick={() => setUseQrReader(false)}
                            />
                            <QrReader
                              className="h-44 w-44 rounded-full"
                              constraints={{
                                height: { ideal: 300 },
                              }}
                              onResult={(result) => {
                                const text = result?.getText();
                                if (text) {
                                  setSendAddress(text);
                                  setUseQrReader(false);
                                }
                              }}
                            />
                          </>
                        )}
                        <div className="relative w-full">
                          <Input
                            placeholder="GummiWorm address to send to..."
                            type="text"
                            value={sendAddress}
                            onChange={(e) => setSendAddress(e.target.value)}
                          />
                          <QrCodeIcon
                            className="absolute right-4 top-0 h-full w-[25px]"
                            onClick={() => setUseQrReader(true)}
                          />
                        </div>
                        <div className="flex items-center justify-center gap-4">
                          <Button
                            variant="neutral"
                            onClick={handleClose}
                            size="xl"
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={current.amount === 0n || !sendAddress}
                            onClick={() => handleSend()}
                            size="xl"
                          >
                            Send
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            ),
          },
          {
            id: "receive",
            content: (
              <QRCode
                value={gummi.address}
                className="mx-auto h-64 w-64 py-8"
              />
            ),
            trigger: <Text tag="p">Receive</Text>,
          },
        ]}
      />
    </Dialog>
  );
};

export default memo(SendReceiveDialog);
