import {
  ArrowPathIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/react/24/outline";
import {
  Avatar,
  Badge,
  Balance,
  Button,
  Card,
  FlexList,
  FlexListItem,
  Loader,
  Notice,
  ScrollArea,
  Text,
  Tooltip,
} from "@sundae/ui-toolkit";
import { useWalletBalance, useWalletDetails } from "@sundae/wallet-react";
import { AssetAmount } from "@sundaeswap/asset";
import { ADA_METADATA, SundaeUtils } from "@sundaeswap/core/utilities";
import truncate from "lodash/truncate";
import { Assets } from "lucid-cardano";
import { FC, useMemo, useState } from "react";

import QRCode from "react-qr-code";
import { IAssetMetadata } from "../../@types/asset";
import { GUMMIWORM_LOVELACE_ASSET_ID } from "../../constants/cardano.constants";
import { useDepositTx } from "../../hooks/useDepositTx";
import { useGummiBalance } from "../../hooks/useGummiBalance";
import { useHasPendingAssets } from "../../hooks/useHasPendingDeposits";
import { useIsWalletConnected } from "../../hooks/useIsWalletConnected";
import { Actions, useAppContext } from "../../stores";
import { getAssetName } from "../../utils/assets.utils";
import ConnectWalletButton from "../ConnectWalletButton";
import CopyText from "../CopyText";
import LoadingComponent from "../LoadingComponent";

const HydraInterface: FC = () => {
  const isWalletConnected = useIsWalletConnected();
  const {
    details: { mainAddress },
  } = useWalletDetails();
  const { pending, confirmations } = useHasPendingAssets();
  const { balance: gummiBalance, gummi, isLoading } = useGummiBalance();
  const { balance, isLoaded } = useWalletBalance();
  const {
    state: {
      deposit: { supplied },
    },
    dispatch,
  } = useAppContext();
  const [showAllGummiWormAssets, setShowAllGummiWormAssets] = useState(false);
  const [showAllPreviewAssets, setShowAllPreviewAssets] = useState(false);

  const visiblePreviewAssets = useMemo(() => {
    if (!balance) {
      return [];
    }

    const assets = balance.getFungibleAssets<IAssetMetadata>();
    return showAllPreviewAssets ? assets : assets.slice(0, 3);
  }, [balance, showAllPreviewAssets]);

  const visibleGummiWormAssets = useMemo(() => {
    if (!gummiBalance || Object.values(gummiBalance).length === 1) {
      return undefined;
    }

    return showAllGummiWormAssets
      ? gummiBalance
      : Object.entries(gummiBalance)
          .slice(0, 3)
          .reduce((acc, [id, amt]) => {
            if (id === "lovelace") {
              return acc;
            }

            acc[id] = amt;
            return acc;
          }, {} as Assets);
  }, [gummiBalance, showAllGummiWormAssets]);

  const { submitTx, isBuildingTx, isSubmittingTx } = useDepositTx();

  return (
    <div className="mx-auto flex min-h-[400px] max-w-6xl items-center justify-center">
      <div className="relative grid w-full grid-cols-2 gap-16">
        <div className="col-span-1 flex flex-col gap-8 rounded-lg border border-primary-200 p-8">
          {!isWalletConnected ? (
            <div className="text-center">
              <Text tag="p" size="xl" className="mb-8">
                Connect your wallet to get started.
              </Text>
              <ConnectWalletButton size="xl" />
            </div>
          ) : (
            <>
              <Text tag="h2" size="3xl" weight="bold">
                Preview
              </Text>
              <Card>
                <div className="flex items-center justify-between">
                  <Text tag="h4" size="xl">
                    Preview Assets
                  </Text>
                  <Tooltip
                    primitiveProps={{
                      content: {
                        className: "overflow-hidden",
                      },
                    }}
                    trigger={
                      <Badge variant="muted" size="sm">
                        {truncate(mainAddress, { length: 20 })}
                      </Badge>
                    }
                    tooltipLabel={
                      <CopyText label="Copy Full Address" text={mainAddress} />
                    }
                  />
                </div>
                {!isLoaded && <LoadingComponent />}
                {isLoaded && (
                  <ScrollArea
                    className="my-8 w-full overflow-hidden pr-4"
                    style={{
                      height: showAllPreviewAssets ? 300 : "auto",
                    }}
                    primitiveProps={{
                      viewport: {
                        className: "h-full",
                      },
                    }}
                  >
                    <FlexList>
                      {visiblePreviewAssets.map(
                        ([, { metadata, quantity }]) => {
                          const matchingSupplied = supplied.find(
                            (supplied) =>
                              supplied.metadata.assetId === metadata.assetId,
                          );

                          const trueBalance = matchingSupplied
                            ? quantity.sub(matchingSupplied)
                            : quantity;

                          return (
                            <FlexListItem
                              key={metadata.assetId}
                              field={{
                                label: (
                                  <div className="flex items-center justify-center gap-2">
                                    <Avatar
                                      src={
                                        SundaeUtils.isAdaAsset(metadata)
                                          ? ADA_METADATA.logo
                                          : metadata.logo
                                          ? `https://cdn.sundaeswap.finance/${metadata.logo}`
                                          : ""
                                      }
                                    />
                                    <Balance
                                      value={trueBalance}
                                      showSymbol={SundaeUtils.isAdaAsset(
                                        metadata,
                                      )}
                                    />
                                    <Text tag="p">
                                      {getAssetName(metadata)}
                                    </Text>
                                  </div>
                                ),
                                size: "sm",
                                tag: "h6",
                              }}
                              value={{
                                label: (
                                  <Button
                                    variant="secondary-outlined"
                                    onClick={() => {
                                      Actions.setDepositAsset(
                                        quantity.withAmount(0n),
                                        dispatch,
                                      );
                                      Actions.setDepositOpen(true, dispatch);
                                    }}
                                  >
                                    Deposit
                                  </Button>
                                ),
                                size: "sm",
                              }}
                            />
                          );
                        },
                      )}
                    </FlexList>
                  </ScrollArea>
                )}

                {isLoaded && balance.getFungibleAssets().length > 3 && (
                  <Button
                    variant="neutral"
                    onClick={() =>
                      setShowAllPreviewAssets(!showAllPreviewAssets)
                    }
                  >
                    {showAllPreviewAssets
                      ? "Condense Preview Asset List"
                      : "Show All Preview Assets"}
                  </Button>
                )}
              </Card>
              {supplied.length > 0 && (
                <Card>
                  <Text tag="h4" size="xl">
                    Pending Preview Transaction
                  </Text>
                  {isLoaded && (
                    <ScrollArea
                      className="my-8 w-full overflow-hidden pr-4"
                      style={{
                        height: showAllPreviewAssets ? 300 : "auto",
                      }}
                      primitiveProps={{
                        viewport: {
                          className: "h-full",
                        },
                      }}
                    >
                      <FlexList>
                        {supplied.map((asset) => (
                          <FlexListItem
                            field={{
                              label: (
                                <div className="flex items-center justify-center gap-2">
                                  <Avatar
                                    src={
                                      SundaeUtils.isAdaAsset(asset.metadata)
                                        ? ADA_METADATA.logo
                                        : asset.metadata.logo
                                        ? `https://cdn.sundaeswap.finance/${asset.metadata.logo}`
                                        : ""
                                    }
                                  />
                                  <Balance
                                    value={asset}
                                    showSymbol={SundaeUtils.isAdaAsset(
                                      asset.metadata,
                                    )}
                                  />
                                  <Text tag="p">
                                    {asset.metadata.ticker ??
                                      asset.metadata.assetName ??
                                      "(Unknown Asset)"}
                                  </Text>
                                </div>
                              ),
                              size: "sm",
                              tag: "h6",
                            }}
                            value={{
                              label: (
                                <Button
                                  variant="secondary-outlined"
                                  onClick={() => {
                                    Actions.removeDepositAsset(asset, dispatch);
                                  }}
                                >
                                  Remove
                                </Button>
                              ),
                              size: "sm",
                            }}
                          />
                        ))}
                      </FlexList>
                    </ScrollArea>
                  )}
                  {supplied.length > 0 && (
                    <Button
                      onClick={() => submitTx()}
                      disabled={isBuildingTx || isSubmittingTx}
                      variant="gradient"
                      className="w-full"
                      size="lg"
                    >
                      Sign &amp; Submit Deposit
                      {isBuildingTx && <Loader size="small" className="mx-6" />}
                    </Button>
                  )}
                </Card>
              )}
            </>
          )}
        </div>
        <ArrowsRightLeftIcon className="absolute left-1/2 top-1/2 z-10 -ml-6 -mt-6 h-12 w-12 text-white" />
        <div className="col-span-1 flex flex-col gap-8 rounded-lg border border-secondary-200 p-8">
          <Text tag="h2" size="3xl" weight="bold">
            GummiWorm
          </Text>
          <Card>
            <div className="flex items-center justify-between">
              <Text tag="h4" size="xl">
                Assets
              </Text>
              {gummi?.address && (
                <Tooltip
                  primitiveProps={{
                    content: {
                      className: "overflow-hidden",
                    },
                  }}
                  trigger={
                    <Badge variant="muted" size="sm">
                      {truncate(gummi.address, { length: 20 })}
                    </Badge>
                  }
                  tooltipLabel={
                    <CopyText label="Copy Full Address" text={gummi.address} />
                  }
                />
              )}
            </div>
            {isLoading && <LoadingComponent />}
            {!isLoading && !visibleGummiWormAssets && gummi && (
              <>
                <QRCode
                  value={gummi.address}
                  className="mx-auto h-64 w-64 py-8"
                />
                <Text tag="h4" size="lg" weight="bold" className="text-center">
                  Receive Assets
                </Text>
                <CopyText
                  primitiveProps={{ text: { size: "xs" } }}
                  label={gummi.address}
                  text={gummi.address}
                />
              </>
            )}
            {!isLoading && visibleGummiWormAssets && (
              <ScrollArea
                className="my-8 w-full overflow-hidden pr-4"
                style={{
                  height: showAllPreviewAssets ? 300 : "auto",
                }}
                primitiveProps={{
                  viewport: {
                    className: "h-full",
                  },
                }}
              >
                <FlexList>
                  {Object.entries(visibleGummiWormAssets).map(([id, amt]) => {
                    const matchingMetadata = balance
                      .getFungibleAssets<IAssetMetadata>()
                      .find(([assetId]) => {
                        if (assetId.split(".")[1] === id.slice(58)) {
                          return true;
                        }
                      })?.[1].metadata ?? {
                      assetId: id,
                      decimals: 6,
                    };

                    const isGummiWormAda =
                      id.slice(56) === GUMMIWORM_LOVELACE_ASSET_ID;

                    const fillInMetadata = isGummiWormAda
                      ? {
                          ...ADA_METADATA,
                          assetId: id,
                          ticker: undefined,
                          assetName: undefined,
                        }
                      : {
                          ...matchingMetadata,
                          assetId: id,
                          ticker: undefined,
                          assetName: undefined,
                        };

                    return (
                      <FlexListItem
                        key={id}
                        field={{
                          label: (
                            <div className="flex items-center justify-center gap-2">
                              <Avatar
                                src={
                                  isGummiWormAda
                                    ? // @ts-ignore
                                      fillInMetadata.logo
                                    : // @ts-ignore
                                      `https://cdn.sundaeswap.finance/${fillInMetadata.logo}`
                                }
                              />
                              <Balance
                                value={new AssetAmount(amt, fillInMetadata)}
                                showSymbol={SundaeUtils.isAdaAsset(
                                  fillInMetadata,
                                )}
                              />
                              <Text tag="p">
                                {getAssetName(fillInMetadata)}
                              </Text>
                            </div>
                          ),
                          size: "sm",
                          tag: "h6",
                        }}
                        value={{
                          label: (
                            <Button
                              variant="secondary-outlined"
                              onClick={() => {
                                Actions.setSendAsset(
                                  new AssetAmount(0n, fillInMetadata),
                                  dispatch,
                                );
                                Actions.setSendReceiveOpen(true, dispatch);
                              }}
                            >
                              Send / Receive
                            </Button>
                          ),
                          size: "sm",
                        }}
                      />
                    );
                  })}
                </FlexList>
              </ScrollArea>
            )}
            {isLoaded &&
              visibleGummiWormAssets &&
              Object.values(visibleGummiWormAssets).length > 3 && (
                <Button
                  variant="neutral"
                  onClick={() =>
                    setShowAllGummiWormAssets(!showAllGummiWormAssets)
                  }
                >
                  {showAllGummiWormAssets
                    ? "Condense GummiWorm Asset List"
                    : "Show All GummiWorm Assets"}
                </Button>
              )}
            {pending && confirmations > 0 && (
              <Notice
                className="mt-8"
                type="success"
                icon={<ArrowPathIcon className="h-4 w-4 animate-spin" />}
                size="xs"
                title="Deposit pending..."
              >
                <Text tag="p" size="sm">
                  You've recently submitted a deposit to the GummiWorm node!
                  There {confirmations > 1 ? "are" : "is"} {confirmations}{" "}
                  confirmation{confirmations > 1 ? "are" : "is"} left...
                </Text>
              </Notice>
            )}
          </Card>
          {false && (
            <Card>
              <Text tag="h4" size="xl">
                Pending Transaction
              </Text>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default HydraInterface;
