import merge from "lodash/merge";
import { FC, HTMLAttributes, PropsWithChildren } from "react";

import FadeInBasic from "../FadeInView/FadeInBasic";

export interface IPageContentFadeEffects {
  fadeIn?: boolean;
  scale?: boolean;
  slideUp?: boolean;
}

interface IPageContentProps extends HTMLAttributes<HTMLDivElement> {
  effects?: IPageContentFadeEffects;
}

export const PageContent: FC<PropsWithChildren<IPageContentProps>> = ({
  children,
  className,
  effects,
}) => {
  const defaultEffects = {
    fadeIn: true,
    scale: false,
    slideUp: false,
  };

  const appliedEffects = merge(defaultEffects, effects);

  return (
    <section>
      <FadeInBasic effects={appliedEffects}>
        <div className={className}>{children}</div>
      </FadeInBasic>
    </section>
  );
};

export default PageContent;
