import { ExternalLink, TARGET_BLANK, Text } from "@sundae/ui-toolkit";
import { FC } from "react";
import { Trans } from "react-i18next";

import { externalRoutes } from "../../../../constants/routes.constants";

const WalletConnectLegalDisclaimer: FC = () => (
  <Text className="flex-1 flex-wrap" tag="p" size="xs">
    <Trans ns="wallet" i18nKey="connectDialog.legalDisclaimer">
      By connecting your wallet, you agree to our
      <ExternalLink
        {...TARGET_BLANK}
        href={externalRoutes.disclaimer}
        onClick={(e) => e.stopPropagation()}
        size="xs"
        variant="primary"
      >
        Disclaimer
      </ExternalLink>
      ,
      <ExternalLink
        {...TARGET_BLANK}
        href={externalRoutes.terms}
        onClick={(e) => e.stopPropagation()}
        size="xs"
        variant="primary"
      >
        Use Terms
      </ExternalLink>
      and our
      <ExternalLink
        {...TARGET_BLANK}
        href={externalRoutes.privacy}
        onClick={(e) => e.stopPropagation()}
        size="xs"
        variant="primary"
      >
        Privacy Policy
      </ExternalLink>
      .
    </Trans>
  </Text>
);

export default WalletConnectLegalDisclaimer;
