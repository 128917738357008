import { useI18N } from "@sundae/react-hooks";
import { Text, Tooltip } from "@sundae/ui-toolkit";
import { FC, PropsWithChildren, memo, useMemo } from "react";

import { useAdaBalance } from "../../hooks/useAdaBalance";
import AdaBalanceLessThanMinimumTooltip from "../../i18n/components/wallet/AdaBalanceLessThanMinimumTooltip";
import AdaBalanceZeroTooltipTrans from "../../i18n/components/wallet/AdaBalanceZeroTooltip/";
import AvailableADABalanceTooltipTrans from "../../i18n/components/wallet/AvailableADABalanceTooltip";

interface IAvailableADABalanceTooltip {
  className?: string;
}

const AvailableADABalanceTooltip: FC<
  PropsWithChildren<IAvailableADABalanceTooltip>
> = ({ className, children }) => {
  const { t } = useI18N("wallet");
  const { adaBalanceFromWallet, isAdaBalanceLessThanMinimum } = useAdaBalance();

  const tooltipLabel = useMemo(() => {
    // assign the balance status into a variable
    const adaBalanceZero = adaBalanceFromWallet?.quantity?.amount === 0n;
    let balanceTooltipLabel = null;

    if (adaBalanceZero) {
      balanceTooltipLabel = <AdaBalanceZeroTooltipTrans />;
    } else if (isAdaBalanceLessThanMinimum) {
      balanceTooltipLabel = <AdaBalanceLessThanMinimumTooltip />;
    } else {
      balanceTooltipLabel = <AvailableADABalanceTooltipTrans />;
    }

    return (
      <div className="grid w-full gap-2">
        {
          // Only render the title `Why don't we use all your ADA?` when the user has > 0 ADA in his wallet.
          !adaBalanceZero && (
            <Text className="max-w-[90%]" tag="h2" size="sm">
              {t("balance.ada", { context: "availableTitle" })}
            </Text>
          )
        }

        {balanceTooltipLabel}
      </div>
    );
  }, [adaBalanceFromWallet, isAdaBalanceLessThanMinimum, t]);

  return (
    <Tooltip
      trigger={<div className={className}>{children}</div>}
      tooltipLabel={tooltipLabel}
    />
  );
};

export default memo(AvailableADABalanceTooltip);
