import { SundaeSDK } from "@sundaeswap/core";
import { Dispatch } from "react";

import { AssetAmount, IAssetAmountMetadata } from "@sundaeswap/asset";
import { Lucid } from "lucid-cardano";
import { IAssetMetadata } from "../../@types/asset";
import { EAppActions, IAppAction } from "./types";

type TActionFunc<T> = (value: T, dispatch: Dispatch<IAppAction>) => void;

/* -------------------------------------------------------------------------------------------------
 * App
 * -----------------------------------------------------------------------------------------------*/

const setConnectWalletOpen: TActionFunc<boolean> = (
  walletConnectOpen,
  dispatch,
) =>
  dispatch({
    type: EAppActions.CONNECT_WALLET_OPEN,
    payload: { walletConnectOpen },
  });

const setWrongNetworkOpen: TActionFunc<boolean> = (
  wrongNetworkOpen,
  dispatch,
) =>
  dispatch({
    type: EAppActions.SET_WRONG_NETWORK_OPEN,
    payload: { wrongNetworkOpen },
  });

const setDepositOpen: TActionFunc<boolean> = (depositOpen, dispatch) =>
  dispatch({
    type: EAppActions.SET_DEPOSIT_OPEN,
    payload: { depositOpen },
  });

const setSendReceiveOpen: TActionFunc<boolean> = (sendReceiveOpen, dispatch) =>
  dispatch({
    type: EAppActions.SET_SEND_RECEIVE_OPEN,
    payload: { sendReceiveOpen },
  });

const setSendAsset: TActionFunc<
  AssetAmount<IAssetAmountMetadata> | undefined
> = (send, dispatch) =>
  dispatch({
    type: EAppActions.SET_SEND_CURRENT,
    payload: { send },
  });

const clearDepositAssets = (dispatch: Dispatch<IAppAction>) => {
  dispatch({
    type: EAppActions.CLEAR_DEPOSIT_ASSETS,
  });
};

const updateDepositAssets: TActionFunc<AssetAmount<IAssetMetadata>> = (
  deposit,
  dispatch,
) =>
  dispatch({
    type: EAppActions.UPDATE_DEPOSIT_ASSETS,
    payload: { deposit },
  });

const removeDepositAsset: TActionFunc<AssetAmount<IAssetMetadata>> = (
  asset,
  dispatch,
) =>
  dispatch({
    type: EAppActions.REMOVE_DEPOSIT_ASSETS,
    payload: { asset },
  });

const setDepositAsset: TActionFunc<AssetAmount<IAssetMetadata> | undefined> = (
  deposit,
  dispatch,
) =>
  dispatch({
    type: EAppActions.SET_DEPOSIT_CURRENT,
    payload: { deposit },
  });

const setSDK: TActionFunc<SundaeSDK> = (sdk, dispatch) =>
  dispatch({ type: EAppActions.SET_SDK, payload: { sdk } });

const setGummiWallet: TActionFunc<Lucid> = (gummiWallet, dispatch) =>
  dispatch({ type: EAppActions.SET_GUMMI_WALLET, payload: { gummiWallet } });

/* -------------------------------------------------------------------------------------------------
 * Export
 * -----------------------------------------------------------------------------------------------*/
export const Actions = {
  setSDK,
  setGummiWallet,
  setConnectWalletOpen,
  setWrongNetworkOpen,
  setSendReceiveOpen,
  setSendAsset,
  setDepositOpen,
  setDepositAsset,
  updateDepositAssets,
  clearDepositAssets,
  removeDepositAsset,
};
