export const paths = {
  root: "/",
};

export const externalRoutes = {
  about: "https://sundae.fi/",
  contact: "https://sundae.fi/contact",
  disclaimer: "https://sundae.fi/terms",
  discord: "https://discord.gg/Sundae",
  explorer: {
    mainnet: {
      cardanoscan: "https://cardanoscan.io",
      cexplorer: "https://cexplorer.io",
    },
    preview: {
      cardanoscan: "https://preview.cardanoscan.io",
      cexplorer: "https://preview.cexplorer.io",
    },
  },
  faq: "https://help.sundaeswap.finance/en/",
  faqFarms:
    "https://help.sundaeswap.finance/en/collections/3299695-yield-farming",
  feesHelpArticle:
    "https://help.sundaeswap.finance/en/articles/7338192-minimum-ada-available-balance",
  governance: "https://governance.sundaeswap.finance/",
  handlePersonalization:
    "https://medium.com/ada-handle/how-to-personalization-e8368e7566d7",
  hotjar: "https://www.hotjar.com/",
  learnMoreAboutWallets:
    "https://help.sundaeswap.finance/en/collections/3303291-wallets",
  medium: "https://sundaeswap-finance.medium.com/",
  privacy: "https://sundae.fi/privacy",
  tasteTestArticle:
    "https://sundae.fi/posts/sundaeswap-presents-the-taste-test",
  terms: "https://sundae.fi/terms",
  reddit: "https://www.reddit.com/r/SundaeSwap/",
  risksAndRewards:
    "https://help.sundaeswap.finance/en/articles/5887307-what-are-the-risks-and-rewards",
  twitter: "https://twitter.com/SundaeSwap",
  liquidity: "https://help.sundaeswap.finance/en/collections/3281728-liquidity",
  cardanoTokenRegistryHelp:
    "https://help.sundaeswap.finance/en/articles/7336428-the-cardano-token-registry",
  cryptoTaxCalculatorManual:
    "https://app.cryptotaxcalculator.io/imports/custom/manual",
  cryptoTaxCalculatorAssistance:
    "https://cryptotaxcalculator.io/guides/advanced-manual-csv-import",
  cip45VideoDemonstration: "https://youtu.be/mRpXIh-DyYM?si=bcPCfNhGMQryTpCX",
  cip45LearnMore:
    "https://github.com/fabianbormann/CIPs/blob/master/CIP-%3F%3F%3F%3F/README.md",
};
