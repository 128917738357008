import { Loader, Text } from "@sundae/ui-toolkit";
import { ILoaderProps } from "@sundae/ui-toolkit/dist/types/components/atoms/Loader/Loader";
import cx from "classnames";
import { FC } from "react";

import Motion from "../Motion";

export interface ILoadingComponentProps {
  className?: string;
  label?: string;
  size?: ILoaderProps["size"];
}

const LoadingComponent: FC<ILoadingComponentProps> = ({
  label,
  size = "medium",
  className,
  ...rest
}) => {
  return (
    <Motion
      className={cx(
        "flex h-full flex-col items-center justify-center gap-6",
        className,
      )}
      {...rest}
    >
      {label && (
        <Text size="sm" tag="span">
          {label}
        </Text>
      )}

      <Loader size={size} />
    </Motion>
  );
};

export default LoadingComponent;
