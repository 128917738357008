import { ExternalLink, Text } from "@sundae/ui-toolkit";
import { ITextProps } from "@sundae/ui-toolkit/dist/types/components/atoms/Text/Text";
import { FC, memo } from "react";
import { Trans } from "react-i18next";
import { externalRoutes } from "../../../../constants/routes.constants";

interface IAdaBalanceZeroTooltipProps {
  variant?: ITextProps["variant"];
}

const AdaBalanceZeroTooltip: FC<IAdaBalanceZeroTooltipProps> = ({
  variant = "muted",
}) => {
  const minAmount = "2";
  return (
    <Text tag="span" size="xs" variant={variant}>
      <Trans ns="wallet" i18nKey="balance.ada" context="noAda">
        You currently don't have any ADA in your wallet. Please add more than{" "}
        {{ minAmount }} ADA to your wallet if you want to perform trades on
        SundaeSwap. Read more{" "}
        <ExternalLink
          // prevents Popovers or other elements to open if the link is clicked and the tooltip is rendered inside the component
          onClick={(e) => e.stopPropagation()}
          href={externalRoutes.feesHelpArticle}
          arrow="top-right"
          variant="primary"
          size="xs"
        >
          here
        </ExternalLink>
      </Trans>
    </Text>
  );
};

export default memo(AdaBalanceZeroTooltip);
