import { IMappedAsset, useWalletBalance } from "@sundae/wallet-react";
import { AssetAmount } from "@sundaeswap/asset";
import { ADA_METADATA } from "@sundaeswap/core";
import { useMemo } from "react";

import { MIN_ADA_WALLET_BALANCE } from "../constants/cardano.constants";

export const useAdaBalance = () => {
  const { balance, isFetched } = useWalletBalance();

  const adaBalanceFromWallet = useMemo(() => {
    if (!balance) return;

    const adaQuantity = balance.getMap().get("ada.lovelace");
    return {
      metadata: adaQuantity?.metadata,
      quantity: new AssetAmount(adaQuantity?.quantity?.amount, ADA_METADATA),
    } as IMappedAsset;
  }, [balance]);

  const isAdaBalanceLessThanMinimum = useMemo(() => {
    if (!adaBalanceFromWallet?.quantity) return false;

    return MIN_ADA_WALLET_BALANCE.value.greaterThan(
      adaBalanceFromWallet.quantity.value,
    );
  }, [adaBalanceFromWallet]);

  const availableAdaBalance = useMemo(() => {
    if (isAdaBalanceLessThanMinimum) {
      return {
        ...adaBalanceFromWallet,
        quantity: new AssetAmount(0n, ADA_METADATA),
      };
    }

    return {
      ...adaBalanceFromWallet,
      quantity: new AssetAmount(
        adaBalanceFromWallet?.quantity.sub(MIN_ADA_WALLET_BALANCE).amount,
        ADA_METADATA,
      ),
    };
  }, [adaBalanceFromWallet, isAdaBalanceLessThanMinimum]);

  return {
    availableAdaBalance,
    adaBalanceFromWallet,
    isAdaBalanceLessThanMinimum,
    loaded: Boolean(balance),
    isFetched,
  };
};
