import { TEnvName } from "../@types/AppConfig";

export const isWrongNetwork = (network: number): boolean =>
  // Changed to a check for ".includes('mainnet') since we are also testing for the eternl environment
  (network === 1 && !window.__APP_CONFIG.envName.includes("mainnet")) ||
  (network === 0 && window.__APP_CONFIG.envName.includes("mainnet"));

export const getEnv = () =>
  (["eternl-mainnet", "mainnet"] as Partial<TEnvName>[]).includes(
    window.__APP_CONFIG.envName,
  )
    ? "mainnet"
    : "preview";
