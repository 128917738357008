import { EStrategy } from "@sundae/wallet-react";
import {
  Context,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

export interface IWalletStrategyContext {
  strategy: EStrategy;
  setStrategy: Dispatch<SetStateAction<EStrategy>>;
}

const defaultWalletStrategyContext: IWalletStrategyContext = {
  strategy: EStrategy.CIP30,
  // @ts-ignore
  setStrategy: (_strategy: EStrategy) => {},
};

const WalletStrategyContext: Context<IWalletStrategyContext> = createContext(
  defaultWalletStrategyContext,
);

export const WalletStrategyContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [strategy, setStrategy] = useState<EStrategy>(EStrategy.CIP30);

  return (
    <WalletStrategyContext.Provider value={{ strategy, setStrategy }}>
      {children}
    </WalletStrategyContext.Provider>
  );
};

// Utility hook.
export const useWalletStrategyContext = (): IWalletStrategyContext =>
  useContext(WalletStrategyContext);
