import { TooltipProvider } from "@sundae/ui-toolkit";
import {
  WalletProvider,
  type IWalletProviderOptions,
} from "@sundae/wallet-react";
import { type IAssetAmountMetadata } from "@sundaeswap/asset";
import { ADA_METADATA, SundaeUtils } from "@sundaeswap/core/utilities";
import request from "graphql-request";
import { FC, ReactNode, lazy, useMemo } from "react";

import {
  GetAssetsDocument,
  GetAssetsQuery,
} from "../../gql/generated/bramble.sdk";
import { AppContextProvider } from "../../stores";
import { useWalletStrategyContext } from "../../stores/WalletStrategyContext";
import { normalizeAdaAssetIdsForGql } from "../../utils/assets.utils";

export type TProvidersProps = {
  children: ReactNode;
};

const LazyReactQueryDevTools =
  process.env.NODE_ENV === "development"
    ? lazy(() =>
        import("@tanstack/react-query-devtools").then((m) => ({
          default: m.ReactQueryDevtools,
        })),
      )
    : () => null;

export const Providers: FC<TProvidersProps> = ({ children }) => {
  const { strategy } = useWalletStrategyContext();
  const options = useMemo<IWalletProviderOptions>(
    () => ({
      useHandles: true,
      strategy,
      dAppName: "GummiWorm Demo",
      refreshRates: {
        balance: 10000,
        details: 30000,
      },
      metadataResolver: async function (
        assetIds,
      ): Promise<IAssetAmountMetadata[]> {
        const normalizedAssetIds = normalizeAdaAssetIdsForGql(assetIds);

        const response: GetAssetsQuery = await request(
          window.__APP_CONFIG.apiUrls.bramble,
          GetAssetsDocument,
          {
            ids: normalizedAssetIds,
          },
        );

        const assets = response.assets.byIds;

        return assets.map(({ id, ...rest }) =>
          SundaeUtils.isAdaAsset({ assetId: id, decimals: rest.decimals, id })
            ? ADA_METADATA
            : {
                assetId: id,
                ...rest,
              },
        );
      },
    }),
    [strategy],
  );

  return (
    <WalletProvider options={options}>
      <AppContextProvider>
        <TooltipProvider delayDuration={150}>{children}</TooltipProvider>
      </AppContextProvider>
      <LazyReactQueryDevTools />
    </WalletProvider>
  );
};

export default Providers;
