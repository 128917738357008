import { EAppActions, IAppAction, IAppState } from "./types";

export const appReducer = (
  prevState: IAppState,
  action: IAppAction,
): IAppState => {
  switch (action.type) {
    /* -------------------------------------------------------------------------------------------------
     * App
     * -----------------------------------------------------------------------------------------------*/
    case EAppActions.CONNECT_WALLET_OPEN:
      return {
        ...prevState,
        dialogs: {
          ...prevState.dialogs,
          walletConnectOpen: action.payload.walletConnectOpen,
        },
      };
    case EAppActions.SET_WRONG_NETWORK_OPEN:
      return {
        ...prevState,
        dialogs: {
          ...prevState.dialogs,
          wrongNetworkOpen: action.payload.wrongNetworkOpen,
        },
      };
    case EAppActions.SET_SEND_RECEIVE_OPEN:
      return {
        ...prevState,
        dialogs: {
          ...prevState.dialogs,
          sendReceiveOpen: action.payload.sendReceiveOpen,
        },
      };
    case EAppActions.SET_SEND_CURRENT:
      return {
        ...prevState,
        send: {
          current: action.payload.send,
        },
      };
    case EAppActions.SET_SDK:
      return {
        ...prevState,
        sdk: action.payload.sdk,
      };
    case EAppActions.SET_GUMMI_WALLET:
      return {
        ...prevState,
        gummiWallet: action.payload.gummiWallet,
      };
    case EAppActions.SET_DEPOSIT_OPEN:
      return {
        ...prevState,
        dialogs: {
          ...prevState.dialogs,
          depositOpen: action.payload.depositOpen,
        },
      };
    case EAppActions.SET_DEPOSIT_CURRENT:
      return {
        ...prevState,
        deposit: {
          ...prevState.deposit,
          current: action.payload.deposit,
        },
      };
    case EAppActions.CLEAR_DEPOSIT_ASSETS:
      return {
        ...prevState,
        deposit: {
          supplied: [],
        },
      };
    case EAppActions.UPDATE_DEPOSIT_ASSETS:
      const updatedSupplied = [...prevState.deposit.supplied];
      const updatedIndex = updatedSupplied.findIndex(
        ({ metadata }) =>
          metadata.assetId === action.payload.deposit.metadata.assetId,
      );
      if (updatedIndex !== -1) {
        updatedSupplied[updatedIndex].withAmount(action.payload.amount);
      } else {
        updatedSupplied.push(action.payload.deposit);
      }

      return {
        ...prevState,
        deposit: {
          ...prevState.deposit.supplied,
          supplied: updatedSupplied,
        },
      };
    case EAppActions.REMOVE_DEPOSIT_ASSETS:
      return {
        ...prevState,
        deposit: {
          ...prevState.deposit,
          supplied: prevState.deposit.supplied.filter(
            ({ metadata }) =>
              metadata.assetId !== action.payload.asset.metadata.assetId,
          ),
        },
      };
    default:
      throw new Error("No action was found!");
  }
};
