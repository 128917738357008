import { useI18N } from "@sundae/react-hooks";
import { Balance, Button, Dialog, Slider } from "@sundae/ui-toolkit";

import { useWalletBalance } from "@sundae/wallet-react";
import { useCallback, useMemo } from "react";
import { Actions, useAppContext } from "../../../stores";
import LoadingComponent from "../../LoadingComponent";

const DepositAssetDialog: React.FC = () => {
  const { t } = useI18N("deposit");
  const { balance, isLoaded } = useWalletBalance();
  const {
    state: {
      dialogs: { depositOpen },
      deposit: { current },
    },
    dispatch,
  } = useAppContext();

  const currentAssetInWallet = useMemo(
    () =>
      balance
        ?.getFungibleAssets()
        .find(([key]) => current?.metadata.assetId === key)?.[1],
    [balance, current?.metadata?.assetId],
  );

  const totalAmount = useMemo(() => {
    if (!isLoaded || !current || !currentAssetInWallet) {
      return 0;
    }

    return Number(currentAssetInWallet.quantity.amount.toString());
  }, [isLoaded, currentAssetInWallet]);

  const handleClose = useCallback(() => {
    Actions.setDepositOpen(false, dispatch);
    Actions.setDepositAsset(undefined, dispatch);
  }, [dispatch]);

  const name = useMemo(() => {
    return (
      current?.metadata?.ticker ||
      current?.metadata?.name ||
      Buffer.from(
        current?.metadata?.assetNameHex ?? current?.metadata?.assetId ?? "",
        "hex",
      ).toString("utf-8")
    );
  }, [current?.metadata]);

  const handleDeposit = useCallback(() => {
    if (!current) {
      return;
    }

    Actions.updateDepositAssets(current, dispatch);
    handleClose();
  }, [dispatch, current, handleClose]);

  if (!current) {
    return null;
  }

  return (
    <Dialog
      open={depositOpen}
      closeDialog={handleClose}
      description={t("description", { assetName: name })}
      title={t("title", { assetName: name })}
    >
      <div className="flex flex-col items-center justify-center gap-8">
        {!isLoaded && <LoadingComponent />}
        {isLoaded && (
          <>
            <Slider
              value={[Number(current.amount.toString())]}
              onValueChange={(value) => {
                Actions.setDepositAsset(
                  current?.withAmount(value[0]),
                  dispatch,
                );
              }}
              max={totalAmount}
            />
            <Balance size="4xl" weight="bold" value={current} />
            <div className="flex items-center justify-center gap-4">
              <Button variant="neutral" onClick={handleClose} size="xl">
                Cancel
              </Button>
              <Button
                disabled={current.amount === 0n}
                onClick={handleDeposit}
                size="xl"
              >
                Add to Deposit
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default DepositAssetDialog;
