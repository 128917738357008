import { useClipboardCopy } from "@sundae/react-hooks";
import { CheckCircledIcon, CopyIcon, Text } from "@sundae/ui-toolkit";
import { ITextProps } from "@sundae/ui-toolkit/dist/types/components/atoms/Text/Text";
import { FC, useCallback, useMemo } from "react";

interface ICopyTextProps {
  text: string;
  label: string;
  primitiveProps?: {
    text?: Partial<ITextProps>;
  };
}

const CopyText: FC<ICopyTextProps> = ({ text, label, primitiveProps }) => {
  const { copy, copied } = useClipboardCopy({ copiedTimeout: 1500 });
  const handleCopy = useCallback(() => {
    copy(text);
  }, [copy, text]);

  const textProps = useMemo(() => {
    const props: ITextProps = {
      onClick: handleCopy,
      tag: "span",
      size: "sm",
      className: "flex w-full cursor-pointer items-center gap-2 text-ellipsis",
    };

    if (!primitiveProps?.text) {
      return props;
    }

    return {
      ...props,
      ...primitiveProps.text,
    };
  }, [primitiveProps?.text]);

  return (
    <Text {...textProps}>
      {label}
      {!copied && <CopyIcon className="h-4 w-4" />}
      {copied && <CheckCircledIcon className="h-4 w-4 text-success" />}
    </Text>
  );
};

export default CopyText;
