import { useI18N } from "@sundae/react-hooks";
import { ErrorFallback as ErrorFallbackBase } from "@sundae/ui-toolkit";
import { useWalletDetails } from "@sundae/wallet-react";
import { FC } from "react";
import { FallbackProps } from "react-error-boundary";

import { ERRORS_API_URL } from "../../constants/api.constants";
import ErrorDetailsMessage from "../../i18n/components/error/ErrorDetailsMessage";
import ErrorFallbackMessage from "../../i18n/components/error/ErrorFallbackMessage";

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const { t } = useI18N("error-fallback");
  const {
    details: { mainAddress },
    activeWallet,
  } = useWalletDetails();

  return (
    <div className="px-6 pb-6 pt-24">
      <ErrorFallbackBase
        renderFallbackMessage={() => <ErrorFallbackMessage />}
        renderCollapsibleTrigger={(toggle) => (
          <ErrorDetailsMessage toggleCollapsible={toggle} />
        )}
        errorReportingEnabled={false}
        activeWallet={activeWallet}
        walletAddress={mainAddress}
        error={error}
        /**
         * @TODO Allow arbitrary values in the report data in UI Toolkit
         */
        // @ts-ignore
        getReportData={async () => {
          return {
            buildInfo: {
              ...window.__BUILD_CONFIG,
              buildAt: new Date(window.__BUILD_CONFIG.builtAt),
            },
          };
        }}
        resetErrorBoundary={resetErrorBoundary}
        errorReportingEndpoint={ERRORS_API_URL}
        i18n={{
          cancelButtonText: t("dontSend"),
          confirmButtonText: t("sentReport"),
          dateHeader: t("date", { ns: "common" }),
          dialogTitle: t("title"),
          errorMessageHeader: t("errorMessage"),
          errorStackHeader: t("errorStack"),
          osHeader: t("os", { ns: "common" }),
          reportIdHeader: t("reportID", { ns: "common" }),
          urlHeader: t("url", { ns: "common" }),
          walletAddressHeader: t("walletAddress"),
          walletInfoHeader: t("wallet", { ns: "glossary" }),
          browserHeader: t("browser", { ns: "common" }),
        }}
      />
    </div>
  );
};

export default ErrorFallback;
