import { useI18N } from "@sundae/react-hooks";
import { Button, Dialog, ExitIcon } from "@sundae/ui-toolkit";
import { useWallet, useWalletDetails } from "@sundae/wallet-react";
import { FC, useCallback, useEffect } from "react";

import { Actions, useAppContext } from "../../../stores";
import { isWrongNetwork } from "../../../utils/network.utils";

const WrongNetworkDialog: FC = () => {
  const { t } = useI18N("wallet");
  const { details } = useWalletDetails();
  const { logoutWallet } = useWallet();
  const {
    state: {
      dialogs: { wrongNetworkOpen },
    },
    dispatch,
  } = useAppContext();

  useEffect(() => {
    if (details && typeof details?.network === "undefined") {
      return;
    }

    if (isWrongNetwork(details?.network)) {
      Actions.setWrongNetworkOpen(true, dispatch);
    }
  }, [details]);

  const handleDisconnect = useCallback(async () => {
    await logoutWallet().then(() => {
      location.reload();
    });
  }, []);

  return (
    <Dialog
      open={wrongNetworkOpen}
      closeDialog={() => Actions.setWrongNetworkOpen(false, dispatch)}
      description={t("dialogs.wrong_net_desc", {
        network: window.__APP_CONFIG.envName,
      })}
      title={t("dialogs.wrong_net_title")}
    >
      <div className="flex items-center justify-center gap-8">
        <Button variant="primary" onClick={handleDisconnect}>
          {t("dialogs.wrong_net_action")}
          <ExitIcon className="fill-current" />
        </Button>
        <Button
          variant="neutral"
          onClick={() => Actions.setWrongNetworkOpen(false, dispatch)}
        >
          {t("dialogs.wrong_net_dismiss")}
        </Button>
      </div>
    </Dialog>
  );
};

export default WrongNetworkDialog;
