import { useQuery } from "@tanstack/react-query";

import { Assets } from "lucid-cardano";
import { useAppContext } from "../stores";
import { useTempWallet } from "./useTempWallet";

export const useGummiBalance = () => {
  const { gummi } = useTempWallet();
  const {
    state: { gummiWallet },
  } = useAppContext();

  const { data: balance, isLoading } = useQuery(
    [gummi, gummiWallet?.network, "gummiBalance"],
    async () => {
      if (!gummi || !gummiWallet) {
        return null;
      }

      const newBalance: Assets = {
        lovelace: 0n,
      };

      const utxos = await gummiWallet.wallet.getUtxos();
      Object.values(utxos).forEach(({ assets }) => {
        for (const [policyId, value] of Object.entries(assets)) {
          // Lovelace can't be minted on L2.
          if (policyId === "lovelace") {
            continue;
          }

          if (typeof value === "bigint") {
            if (!newBalance[policyId]) {
              newBalance[policyId] = value;
            } else {
              newBalance[policyId] += value;
            }
          } else if (typeof value === "object") {
            for (const [assetName, amount] of Object.entries<bigint>(value)) {
              const assetId = policyId + assetName;
              if (!newBalance[assetId]) {
                newBalance[assetId] = amount;
              } else {
                newBalance[assetId] += amount;
              }
            }
          }
        }
      });

      return newBalance;
    },
    {
      refetchInterval: 500,
    },
  );

  return {
    balance,
    gummi,
    isLoading,
  };
};
