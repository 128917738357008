import { useWalletDetails } from "@sundae/wallet-react";
import { ETxBuilderType, SundaeSDK } from "@sundaeswap/core";
import { GummiProvider } from "@sundaeswap/gummiworm";
import { Blockfrost, C, Lucid } from "lucid-cardano";
import {
  Context,
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import { useTempWallet } from "../../hooks/useTempWallet";
import { getEnv } from "../../utils/network.utils";
import { Actions } from "./actions";
import { appReducer } from "./reducer";
import { IAppAction, IAppContext, IAppState } from "./types";

export const defaultAppState: IAppState = {
  sdk: null,
  gummiWallet: null,
  dialogs: {
    walletConnectOpen: false,
    wrongNetworkOpen: false,
    depositOpen: false,
    sendReceiveOpen: false,
  },
  deposit: {
    supplied: [],
  },
  send: {},
};

const AppContext: Context<IAppContext> = createContext({
  state: defaultAppState,
  dispatch: (_value: IAppAction) => {},
});

export const AppContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { gummi } = useTempWallet();
  const [state, dispatch] = useReducer(appReducer, defaultAppState);
  const { activeWallet } = useWalletDetails();

  const memoizedDispatch = useMemo(() => dispatch, []);

  useEffect(() => {
    (async () => {
      const network = getEnv();
      const lucidNetwork = network === "mainnet" ? "Mainnet" : "Preview";

      if (gummi) {
        const gummiLucid = await Lucid.new(
          new GummiProvider(lucidNetwork),
          lucidNetwork,
        );

        gummiLucid.selectWalletFromPrivateKey(
          C.PrivateKey.from_normal_bytes(
            Buffer.from(gummi.privateKey, "hex"),
          ).to_bech32(),
        );
        Actions.setGummiWallet(gummiLucid, dispatch);
      }

      if (activeWallet) {
        const lucid = await Lucid.new(
          new Blockfrost(
            window.__APP_CONFIG.blockfrostUrl,
            window.__APP_CONFIG.blockfrostKey,
          ),
          network === "mainnet" ? "Mainnet" : "Preview",
        );
        lucid.selectWallet(await window.cardano[activeWallet].enable());

        const sdk = new SundaeSDK({
          wallet: {
            builder: {
              type: ETxBuilderType.LUCID,
              lucid,
            },
            name: activeWallet,
            network,
          },
        });

        Actions.setSDK(sdk, dispatch);
      }
    })();
  }, [activeWallet, gummi]);

  return (
    <AppContext.Provider value={{ state, dispatch: memoizedDispatch }}>
      {children}
    </AppContext.Provider>
  );
};

// Utility hook.
export const useAppContext = (): IAppContext => useContext(AppContext);
