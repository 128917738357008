import { useEffect, useState } from "react";

import { INITIAL_PAGE_TRANSITION_DURATION } from "../components/InitialPageTransition/InitialPageTransition";

export const useIsInitialPageLoad = () => {
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

  // this effect sets `isInitialPageLoad` to false and makes sure that we do not trigger the initial page transition a second time
  useEffect(() => {
    setTimeout(
      () => isInitialPageLoad && setIsInitialPageLoad(false),
      1000 * INITIAL_PAGE_TRANSITION_DURATION,
    );
  }, []);

  return { isInitialPageLoad };
};
