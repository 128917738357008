import { AssetAmount, IAssetAmountMetadata } from "@sundaeswap/asset";
import { SundaeSDK } from "@sundaeswap/core";
import { Lucid } from "lucid-cardano";
import { Dispatch } from "react";
import { IAssetMetadata } from "../../@types/asset";

export enum EAppActions {
  /* -------------------------------------------------------------------------------------------------
   * App
   * -----------------------------------------------------------------------------------------------*/
  CONNECT_WALLET_OPEN = "CONNECT_WALLET_OPEN",
  SET_SDK = "SET_SDK",
  SET_GUMMI_WALLET = "SET_GUMMI_WALLET",
  SET_CONNECTED_WALLET = "SET_CONNECTED_WALLET",
  SET_WRONG_NETWORK_OPEN = "SET_WRONG_NETWORK_OPEN",
  SET_SEND_RECEIVE_OPEN = "SET_SEND_RECEIVE_OPEN",
  SET_SEND_CURRENT = "SET_SEND_CURRENT",
  SET_DEPOSIT_OPEN = "SET_DEPOSIT_OPEN",
  SET_DEPOSIT_CURRENT = "SET_DEPOSIT_CURRENT",
  UPDATE_DEPOSIT_ASSETS = "UPDATE_DEPOSIT_ASSETS",
  CLEAR_DEPOSIT_ASSETS = "CLEAR_DEPOSIT_ASSETS",
  REMOVE_DEPOSIT_ASSETS = "REMOVE_DEPOSIT_ASSETS",
}

export interface IAppAction {
  type: string;
  // Payload types are inforced in ./actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export enum ESortingDirection {
  asc = "asc",
  desc = "desc",
}

export interface IDeposit {
  current?: AssetAmount<IAssetMetadata>;
  supplied: AssetAmount<IAssetMetadata>[];
}

export interface ISend {
  current?: AssetAmount<IAssetAmountMetadata>;
}

export interface IAppState {
  sdk: SundaeSDK | null;
  gummiWallet: Lucid | null;
  deposit: IDeposit;
  send: ISend;
  dialogs: {
    walletConnectOpen: boolean;
    wrongNetworkOpen: boolean;
    depositOpen: boolean;
    sendReceiveOpen: boolean;
  };
}

export interface IAppContext {
  state: IAppState;
  dispatch: Dispatch<IAppAction>;
}
