import { useWalletDetails } from "@sundae/wallet-react";
import { useQuery } from "@tanstack/react-query";

import { useAppContext } from "../stores";

export const useHasPendingAssets = () => {
  const {
    state: { sdk },
  } = useAppContext();
  const {
    details: { mainAddress },
  } = useWalletDetails();
  const { data } = useQuery(
    ["pendingAssets", mainAddress],
    async () => {
      let pending = false;
      let confirmations = 0;
      if (!sdk) {
        return { pending, confirmations };
      }

      const L1Utxos = await sdk.builder().lucid.wallet.getUtxos();
      const deposits = await fetch(
        `${window.__APP_CONFIG.apiUrls.rest}/pending_deposits`,
      ).then((res) => res.json());
      for (const deposit of deposits) {
        const matchingUtxo = L1Utxos.find(
          ({ txHash }) => txHash === deposit[0],
        );
        if (matchingUtxo) {
          pending = true;
          confirmations = deposit[1];
          break;
        }
      }

      return {
        pending,
        confirmations,
      };
    },
    {
      initialData: {
        pending: false,
        confirmations: 0,
      },
      refetchInterval: 10000,
    },
  );

  return data;
};
