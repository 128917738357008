import { IAssetAmountMetadata } from "@sundaeswap/asset";
import { SundaeUtils } from "@sundaeswap/core/utilities";
import { IAssetMetadata } from "../@types/asset";

/**
 * We currently receive `ada.lovelace` as assetId from the SDK. We need to normalize it to `cardano.ada` for the API in order to receive the correct asset.
 * @param assetIds - Array of assetIds.
 * @returns Array of assetIds with normalized ADA assetId.
 */
export const normalizeAdaAssetIdsForGql = (assetIds: string[]) => {
  return assetIds.map((assetId) => {
    if (SundaeUtils.ADA_ASSET_IDS.includes(assetId)) {
      return "ada.lovelace";
    }

    return assetId;
  });
};

export const getAssetName = (
  asset: IAssetMetadata | IAssetAmountMetadata,
): string => {
  if (SundaeUtils.isAdaAsset(asset)) {
    return "ADA";
  }

  return (
    asset?.assetName ||
    asset?.ticker ||
    Buffer.from(asset.assetId.slice(56), "hex").toString("utf-8")
  );
};
