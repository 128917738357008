import { FC, lazy } from "react";

import { useIsWalletConnected } from "../../hooks/useIsWalletConnected";
import ConnectWalletButton from "../ConnectWalletButton";

const LazyWalletDetailsPopover = lazy(
  () =>
    import(
      /* webpackChunkName: "WalletDetailsPopover" */ "../WalletDetailsPopover"
    ),
);

const WalletButton: FC = () => {
  const isWalletConnected = useIsWalletConnected();

  if (isWalletConnected) {
    return <LazyWalletDetailsPopover />;
  }

  return <ConnectWalletButton className="ml-auto mr-auto lg:ml-0 lg:mr-0" />;
};

export default WalletButton;
