import { Text } from "@sundae/ui-toolkit";
import { FC } from "react";

import HydraInterface from "../../components/HydraInterface";
import PageContent from "../../components/PageContent";

const Home: FC = () => {
  return (
    <PageContent>
      <div className="my-16 flex flex-col items-center justify-center gap-2">
        <Text tag="h2" size="7xl" weight="bold">
          GummiWorm
        </Text>
        <Text tag="p">An L2 architecture built on the Hydra protocol.</Text>
      </div>

      <HydraInterface />
    </PageContent>
  );
};

export default Home;
