import cx from "classnames";
import merge from "lodash/merge";
import {
  FC,
  HTMLAttributes,
  PropsWithChildren,
  memo,
  useEffect,
  useState,
} from "react";
import { IPageContentFadeEffects } from "../PageContent/PageContent";

interface IFadeInBasicrops extends HTMLAttributes<HTMLDivElement> {
  effects?: IPageContentFadeEffects;
  transitionDelay?: number;
  transitionDuration?: number;
}

const FadeInBasic: FC<PropsWithChildren<IFadeInBasicrops>> = ({
  children,
  className,
  transitionDelay = 75,
  transitionDuration = 1000,
  effects,
  ...rest
}) => {
  const [animate, setAnimate] = useState(false);
  const defaultEffects = {
    fadeIn: true,
    scale: true,
    slideUp: true,
  };

  const { fadeIn, scale, slideUp } = merge(defaultEffects, effects);

  useEffect(() => {
    setTimeout(() => setAnimate(true), transitionDelay);
  }, [transitionDelay]);

  return (
    <div
      {...rest}
      className={cx(
        "rounded-3xl transition",
        {
          // animated classes
          "opacity-100": animate && fadeIn,
          "scale-100": animate && scale,
          "translate-y-0": animate && slideUp,
          // non-animated classes
          "opacity-0": !animate && fadeIn,
          "scale-[98%]": !animate && scale,
          "translate-y-20": !animate && slideUp,
        },
        className,
      )}
      style={{
        ...rest.style,
        transitionDuration: `${transitionDuration}ms`,
      }}
    >
      {children}
    </div>
  );
};

export default memo(FadeInBasic);
