import { useI18N } from "@sundae/react-hooks";
import { Image, Text } from "@sundae/ui-toolkit";
import { ITextProps } from "@sundae/ui-toolkit/dist/types/components/atoms/Text/Text";
import cx from "classnames";
import React from "react";

export interface INoDataIndicatorProps extends Pick<ITextProps, "size"> {
  containerClassName?: string;
  context?: // Context is defined inside `src/i18n/translations/${lang}/common.json` --> `noData_${context}`
  | "activeFarms"
    | "ada"
    | "assets"
    | "assetsWithBalanceError"
    | "charts"
    | "contributions"
    | "createPool"
    | "exchange"
    | "farms"
    | "favoritePools"
    | "handles"
    | "inactiveTasteTests"
    | "liquidity"
    | "news"
    | "ongoingTasteTests"
    | "openOrdersForPool"
    | "orders"
    | "ordersHistory"
    | "pools"
    | "positions"
    | "provideLiquidity"
    | "tasteTestNotFound"
    | "transactions"
    | "wallet"
    | "withdrawLiquidity"
    | "yieldFarmingDelegations"
    | "yieldFarmingEarnings"
    | "yieldFarmingNoVotingPower"
    | "yieldFarmingPositions"
    | "tasteTestExpired";
  imageClassName?: string;
  imageMaxWidth?: number;
  type?: "default" | "table";
}

const NoDataIndicator: React.FC<INoDataIndicatorProps> = ({
  containerClassName,
  context,
  imageClassName,
  imageMaxWidth = 92,
  size = "sm",
  type = "default",
  ...rest
}) => {
  const { t } = useI18N("warnings");
  const imgSrc =
    type === "default"
      ? "/static/images/dudes/froyo_confused_sad_400x400.png"
      : "/static/images/no-data/empty_table.png";

  return (
    <div
      {...rest}
      className={cx(
        "flex w-full flex-col items-center gap-2 p-4",
        containerClassName,
      )}
    >
      <Image
        className={cx(imageClassName)}
        src={imgSrc}
        style={{ maxWidth: `${imageMaxWidth}px` }}
        width={imageMaxWidth}
      />
      <Text className="text-center" size={size} tag="p">
        {t("noData", { context })}
      </Text>
    </div>
  );
};

export default NoDataIndicator;
