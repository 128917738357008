import {
  ArrowRightIcon,
  ArrowTopRightIcon,
  Text,
  toolkitCx,
} from "@sundae/ui-toolkit";
import { ITextProps } from "@sundae/ui-toolkit/dist/types/components/atoms/Text/Text";
import cx from "classnames";
import { FC } from "react";
import { LinkProps, Link as RouterLink } from "react-router-dom";

type TArrowPosition = "top-right" | "right";

export interface ILinkProps
  extends LinkProps,
    Pick<ITextProps, "variant" | "hoverable" | "size" | "weight"> {
  arrow?: TArrowPosition;
  title?: string;
}

export const renderArrow = (arrow?: TArrowPosition, size = "") => {
  if (arrow === "top-right") {
    return (
      <ArrowTopRightIcon
        className={cx(
          "fill-current group-hover:-translate-y-0.5 group-hover:translate-x-0.5",
          {
            "h-3 w-3": ["xs", "sm"].includes(size),
            "h-4 w-4": size === "base",
            "h-5 w-5": ["lg", "xl"].includes(size),
          },
          toolkitCx.layout.transition.transform,
        )}
      />
    );
  }

  if (arrow === "right") {
    return (
      <ArrowRightIcon
        className={cx(
          "self-center fill-current group-hover:translate-x-0.5",
          {
            "h-3 w-3": size === "xs",
            "h-4 w-4": size === "sm",
            "h-5 w-5": size === "base",
            "h-6 w-6": ["lg", "xl"].includes(size),
          },
          toolkitCx.layout.transition.transform,
        )}
      />
    );
  }

  return null;
};

const Link: FC<ILinkProps> = ({
  arrow,
  children,
  className,
  hoverable = true,
  size,
  variant,
  weight,
  ...linkProps
}) => {
  return (
    <Text
      className="group no-underline"
      hoverable={hoverable}
      size={size}
      tag="span"
      variant={variant}
      weight={weight}
    >
      <RouterLink
        {...linkProps}
        className={cx(className, toolkitCx.layout.transition.colors)}
      >
        {children}

        {renderArrow(arrow, size)}
      </RouterLink>
    </Text>
  );
};

export default Link;
