import { useI18N } from "@sundae/react-hooks";
import { Balance, ExternalLink, Text } from "@sundae/ui-toolkit";
import { FC, memo } from "react";
import { Trans } from "react-i18next";
import { useAdaBalance } from "../../../../hooks/useAdaBalance";
import { externalRoutes } from "../../../../constants/routes.constants";

const AvailableADABalanceTooltip: FC = () => {
  const { currentLocale } = useI18N();
  const { adaBalanceFromWallet } = useAdaBalance();

  return (
    <Text tag="span" size="xs" variant="muted">
      <Trans ns="wallet" i18nKey="balance.ada" context="available">
        Even though you have{" "}
        <div className="inline-flex">
          <Balance
            abbreviate={false}
            className="inline"
            locale={currentLocale}
            privacy={false}
            showSymbol
            size="xs"
            value={adaBalanceFromWallet?.quantity}
            variant="muted"
            weight="bold"
          />
        </div>{" "}
        in your wallet, we cannot spend all of it. This is because of the
        necessary fees and deposits. Read more{" "}
        <ExternalLink
          // prevents Popovers or other elements to open if the link is clicked and the tooltip is rendered inside the component
          onClick={(e) => e.stopPropagation()}
          href={externalRoutes.feesHelpArticle}
          arrow="top-right"
          variant="primary"
          size="xs"
        >
          here
        </ExternalLink>
      </Trans>
    </Text>
  );
};

export default memo(AvailableADABalanceTooltip);
