import { Button, Text } from "@sundae/ui-toolkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FC } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import DepositAssetDialog from "./components/Dialogs/DepositAssetDialog";
import SendReceiveDialog from "./components/Dialogs/SendReceiveDialog";
import WalletConnectDialog from "./components/Dialogs/WalletConnectDialog";
import WrongNetworkDialog from "./components/Dialogs/WrongNetworkDialog";
import ErrorFallback from "./components/ErrorFallback";
import Providers from "./components/Providers";
import { paths } from "./constants/routes.constants";
import "./i18n";
import MainLayout from "./layouts/MainLayout";
import Home from "./pages/Home";
import { WalletStrategyContextProvider } from "./stores/WalletStrategyContext";

const queryClient = new QueryClient();

const FallbackComponent: FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <div className="grid gap-2 p-2" role="alert">
      <Text tag="div" size="lg">
        Something went wrong:
      </Text>
      <Text tag="div">{error.message}</Text>
      <Button onClick={resetErrorBoundary} className="w-fit">
        Try again
      </Button>
    </div>
  );
};

const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary
        FallbackComponent={(props) => (
          <ErrorBoundary FallbackComponent={FallbackComponent}>
            <ErrorFallback {...props} />
          </ErrorBoundary>
        )}
      >
        <BrowserRouter
          future={{ v7_relativeSplatPath: true, v7_startTransition: true }}
        >
          <WalletStrategyContextProvider>
            <Providers>
              <Routes>
                <Route path={paths.root} element={<MainLayout />}>
                  <Route index element={<Home />} />
                </Route>
              </Routes>

              <WalletConnectDialog />
              <WrongNetworkDialog />
              <DepositAssetDialog />
              <SendReceiveDialog />
            </Providers>
          </WalletStrategyContextProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;
