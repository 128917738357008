import { useI18N } from "@sundae/react-hooks";
import { WalletConnectDialog as ToolkitWalletConnect } from "@sundae/ui-toolkit";
import { useWalletError } from "@sundae/wallet-react";
import capitalize from "lodash/capitalize";
import { useMemo } from "react";

import { externalRoutes } from "../../../constants/routes.constants";
import { useIsWalletConnected } from "../../../hooks/useIsWalletConnected";
import { useWalletConnectFlow } from "../../../hooks/useWalletConnectFlow";
import WalletConnectLegalDisclaimer from "../../../i18n/components/wallet/WalletConnectLegalDisclaimer";
import NoDataIndicator from "../../NoDataIndicator";

const WalletConnectDialog: React.FC = () => {
  const { t } = useI18N("wallet");
  const { error } = useWalletError();
  const {
    cip45,
    handleCloseWalletConnectDialog,
    handleConnect,
    isCheckingNetwork,
    isConnectingWallet,
    setIsCheckingNetwork,
    setWrongNetwork,
    walletConnectOpen,
    wrongNetwork,
  } = useWalletConnectFlow();
  const isWalletConnected = useIsWalletConnected();

  const errorMessage = useMemo(() => {
    if (wrongNetwork) {
      return t("connectDialog.wrongNetwork", {
        network: capitalize(window.__APP_CONFIG.envName),
      });
    }

    switch (error) {
      case "no account set":
        return t("connectDialog.noAccount");
      default:
        return error;
    }
  }, [error, wrongNetwork]);

  return (
    <ToolkitWalletConnect
      i18n={{
        error: errorMessage,
        learnMore: t("connectDialog.learnMore"),
        noWallets: t("connectDialog.noWalletsCopy"),
        description: t("connectDialog.description"),
        selectWalletNotice: t("connectDialog.selectWalletNotice"),
        suggestWallet: t("connectDialog.suggestWallet"),
        title: t("connectDialog.title"),
        walletConnect: t("button.connect"),
      }}
      legalDisclaimer={<WalletConnectLegalDisclaimer />}
      isLoading={(isCheckingNetwork || isConnectingWallet) && !error}
      isWalletConnected={isWalletConnected}
      learnMoreRoute={externalRoutes.learnMoreAboutWallets}
      noWalletsComponent={<NoDataIndicator context="wallet" />}
      onConnectWallet={handleConnect}
      onClose={() => {
        setIsCheckingNetwork(false);
        setWrongNetwork(false);
        handleCloseWalletConnectDialog();
      }}
      open={walletConnectOpen}
      suggestWalletRoute={externalRoutes.learnMoreAboutWallets}
      cip45={cip45}
    />
  );
};

export default WalletConnectDialog;
