import { Text } from "@sundae/ui-toolkit";
import { memo } from "react";
import { Trans } from "react-i18next";

const ErrorFallbackMessage = memo(() => {
  return (
    <Text tag="div" className="grid gap-2">
      <Trans i18nKey="message" ns="error-fallback">
        A log of this error has been created.
        <Text tag="div" weight="semibold" variant="muted">
          Please tell SundaeSwap about this problem.
        </Text>
        We have created an error report that you can send to help us improve the
        platform.
      </Trans>
    </Text>
  );
});

export default ErrorFallbackMessage;
