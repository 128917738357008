import { EStrategy, useWallet, useWalletDetails } from "@sundae/wallet-react";
import { KnownCip30WalletId } from "@sundae/wallets";
import { ETxBuilderType, SundaeSDK } from "@sundaeswap/core";
import { Blockfrost, Lucid } from "lucid-cardano";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useI18N } from "@sundae/react-hooks";
import {
  Badge,
  ExternalLink,
  InfoCircledIcon,
  Loader,
  Text,
  Tooltip,
} from "@sundae/ui-toolkit";
import { Trans } from "react-i18next";
import { externalRoutes } from "../constants/routes.constants";
import { Actions, useAppContext } from "../stores";
import { useWalletStrategyContext } from "../stores/WalletStrategyContext";
import { getEnv, isWrongNetwork } from "../utils/network.utils";

export const useWalletConnectFlow = () => {
  const { t } = useI18N("wallet");
  const {
    dispatch,
    state: {
      dialogs: { walletConnectOpen },
    },
  } = useAppContext();
  const { connectWallet, isConnectingWallet } = useWallet();
  const { dAppConnector } = useWalletDetails();
  const [isCheckingNetwork, setIsCheckingNetwork] = useState(false);
  const [wrongNetwork, setWrongNetwork] = useState(false);
  const { strategy, setStrategy } = useWalletStrategyContext();
  const qrCodeRef = useRef(null);

  useEffect(() => {
    if (strategy === EStrategy.CIP45 && qrCodeRef.current) {
      dAppConnector?.instance().generateQRCode(qrCodeRef.current);
    }
  }, [strategy, dAppConnector]);

  const handleCloseWalletConnectDialog = useCallback(() => {
    Actions.setConnectWalletOpen(false, dispatch);
  }, []);

  const handleConnect = useCallback(async (id: KnownCip30WalletId) => {
    setWrongNetwork(false);
    setIsCheckingNetwork(true);

    try {
      const api = await window.cardano[id]?.enable();
      if (api && isWrongNetwork(await api.getNetworkId())) {
        setIsCheckingNetwork(false);
        setWrongNetwork(true);
        return;
      }

      /**
       * We don't do anything with this error, because we want to handle wrong network errors explicitly.
       * The reasoning being is that it's not technically an error and so will not throw when running
       * `connectWallet` below.
       */
    } catch (e) {}

    await connectWallet(id);

    const network = getEnv();
    const lucid = await Lucid.new(
      new Blockfrost(
        window.__APP_CONFIG.blockfrostUrl,
        window.__APP_CONFIG.blockfrostKey,
      ),
      network === "mainnet" ? "Mainnet" : "Preview",
    );
    lucid.selectWallet(await window.cardano[id].enable());

    const sdk = new SundaeSDK({
      wallet: {
        builder: {
          type: ETxBuilderType.LUCID,
          lucid,
        },
        name: id,
        network,
      },
    });
    Actions.setSDK(sdk, dispatch);
  }, []);

  const peerId = useMemo(
    () =>
      strategy === EStrategy.CIP45
        ? dAppConnector?.instance().getAddress()
        : "",
    [dAppConnector, strategy],
  );

  const cip45 = useMemo(
    () => ({
      i18n: {
        dialogDescription: (
          <Text tag="span" size="sm">
            <Trans i18nKey="connectDialog.cip45.dialogDescription" ns="wallet">
              To connect to your CIP-45 compatible wallet, scan the QR code
              below, or copy paste the Peer ID. Click{" "}
              <ExternalLink
                href={externalRoutes.cip45VideoDemonstration}
                size="sm"
                variant="primary"
              >
                here
              </ExternalLink>{" "}
              for a video demonstration. Click{" "}
              <ExternalLink
                variant="primary"
                size="sm"
                href={externalRoutes.cip45LearnMore}
              >
                here
              </ExternalLink>{" "}
              to learn more about CIP-45
            </Trans>
          </Text>
        ),
        dialogTitle: (
          <Text
            tag="h1"
            className="flex items-center gap-2"
            size="lg"
            weight="bold"
          >
            {t("connectDialog.cip45.dialogTitle")}
            <Tooltip
              trigger={
                <Badge variant="warning" size="sm">
                  <span className="inline-flex cursor-help items-center justify-center gap-2">
                    <span>{t("connectDialog.cip45.experimental")}</span>
                    <InfoCircledIcon />
                  </span>
                </Badge>
              }
              tooltipLabel={t("connectDialog.cip45.experimental", {
                context: "tooltip",
              })}
            />
          </Text>
        ),
        button: t("connectDialog.cip45.button"),
        dialogCancel: t("connectDialog.cip45.dialogCancel"),
        dialogCopiedId: t("connectDialog.cip45.dialogCopiedId"),
        dialogCopyId: t("connectDialog.cip45.dialogCopyId"),
      },
      onClick: () => {
        setStrategy(EStrategy.CIP45);
      },
      onCancel: () => setStrategy(EStrategy.CIP30),
      peerId,
      qrCode: (
        <div
          className="flex items-center justify-center"
          style={{ width: "256px", height: "256px" }}
        >
          <div ref={qrCodeRef} />
          {!qrCodeRef.current && <Loader size="small" />}
        </div>
      ),
    }),
    [qrCodeRef.current, peerId],
  );

  return {
    cip45,
    handleCloseWalletConnectDialog,
    handleConnect,
    isCheckingNetwork,
    isConnectingWallet,
    setIsCheckingNetwork,
    setWrongNetwork,
    walletConnectOpen,
    wrongNetwork,
  };
};
