import cx from "classnames";
import React from "react";

import { Outlet } from "react-router-dom";
import GradientBackground from "../../components/GradientBackground";
import Header from "../../components/Header";
import InitialPageTransition from "../../components/InitialPageTransition";
import { useIsInitialPageLoad } from "../../hooks/useIsInitialPageLoad";

const MainLayout: React.FC = () => {
  const { isInitialPageLoad } = useIsInitialPageLoad();

  return (
    <>
      <main className="min-h-screen overflow-hidden dark:bg-gray-900 lg:overflow-visible">
        <Header />
        <article
          className={cx("mx-auto p-4 xl:p-6")}
          style={{ marginTop: `calc(5rem)` }}
        >
          {isInitialPageLoad ? <InitialPageTransition /> : <Outlet />}
        </article>
      </main>

      {!isInitialPageLoad && <GradientBackground />}
    </>
  );
};

export default MainLayout;
