import cx from "classnames";
import { motion, MotionProps, Variants } from "framer-motion";
import {
  ElementType,
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  useMemo,
} from "react";

interface IMotion extends MotionProps {
  animationKey?: string;
  className?: string;
  onClick?: () => void;
  tag?: ElementType;
  withDefaultMotionProps?: boolean;
}

export const DEFAULT_DURATION = 0.75;
export const EXIT_DURATION = 0.25;

export const DEFAULT_MOTION_PROPS = {
  animate: "animate",
  exit: "exit",
  initial: "initial",
};

// General Dialog Animation
export const slideInAndScaleFromBottomMotion: Variants = {
  initial: {
    opacity: 0,
    scale: 0.98,
    y: 20,
  },
  exit: {
    opacity: 0,
    scale: 0.98,
    y: 20,
    transition: {
      type: "spring",
      duration: 0.5,
    },
  },
  animate: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: DEFAULT_DURATION,
    },
  },
};

const Motion: ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<IMotion>
> = (
  {
    animationKey,
    children,
    className,
    onClick,
    tag = "div",
    withDefaultMotionProps = true,
    variants = slideInAndScaleFromBottomMotion,
    ...motionProps
  },
  forwardedRef,
) => {
  const MemoizedMotionNode = useMemo(() => motion(tag), [tag]);

  return (
    <MemoizedMotionNode
      {...(withDefaultMotionProps && DEFAULT_MOTION_PROPS)}
      {...motionProps}
      key={animationKey}
      className={cx("will-change-transform", className)}
      ref={forwardedRef}
      onClick={onClick}
      {...variants}
    >
      {children}
    </MemoizedMotionNode>
  );
};

export default forwardRef(Motion);
