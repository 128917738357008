import { useI18N, useResponsive } from "@sundae/react-hooks";
import { Button } from "@sundae/ui-toolkit";
import type { IButtonProps } from "@sundae/ui-toolkit/dist/types/components/atoms/Button/Button";
import { FC, MouseEvent, useCallback, useMemo } from "react";

import { Actions, useAppContext } from "../../stores";

const ConnectWalletButton: FC<IButtonProps> = ({
  size = "sm",
  variant = "gradient",
  ...props
}) => {
  const { dispatch } = useAppContext();
  const { t } = useI18N("wallet");
  const responsive = useResponsive();

  const handleWalletConnectOpen = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      props.onClick?.(e);
      Actions.setConnectWalletOpen(true, dispatch);
    },
    [dispatch, props.onClick],
  );

  const memoizedLabel = useMemo(() => {
    if (responsive.xxl) {
      return t("button.connect");
    }

    if (responsive.md) {
      return t("button.connect", { context: "short" });
    }

    return t("button.connect", { context: "abbr" });
  }, [responsive.xxl, responsive.md]);

  return (
    <Button
      {...props}
      variant={variant}
      onClick={handleWalletConnectOpen}
      size={size}
    >
      {memoizedLabel}
    </Button>
  );
};

export default ConnectWalletButton;
