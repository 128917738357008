import { useWalletDetails } from "@sundae/wallet-react";
import { GummiWormLucid } from "@sundaeswap/gummiworm";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";

import { Actions, useAppContext } from "../stores";
import { useTempWallet } from "./useTempWallet";

export const useDepositTx = () => {
  const { gummi } = useTempWallet();
  const {
    details: { mainAddress },
    activeWallet,
  } = useWalletDetails();
  const {
    state: {
      deposit: { supplied },
      sdk,
    },
    dispatch,
  } = useAppContext();

  useEffect(() => {
    if (supplied.length > 0 && !isBuildingTx) {
      buildDepositTx();
    }
  }, [supplied]);

  const {
    data: builtTx,
    mutateAsync: buildDepositTx,
    isLoading: isBuildingTx,
  } = useMutation([mainAddress, activeWallet, supplied], async () => {
    if (!sdk) {
      throw new Error("Attempted to use an SDK that was not initialized.");
    }

    if (supplied.length === 0) {
      throw new Error("No supplied assets are ready to be deposited.");
    }

    const GW = new GummiWormLucid(sdk);
    return await GW.deposit({
      assets: supplied,
      address: gummi?.address,
    });
  });

  const { mutateAsync: submitTx, isLoading: isSubmittingTx } = useMutation(
    [builtTx],
    async () => {
      if (!builtTx) {
        throw new Error(
          "Attempted to interact with a built transaction that doesn't exist!",
        );
      }

      const txComplete = await builtTx.build();
      const signedTx = await txComplete.sign();
      const txHash = await signedTx.submit();
      return txHash;
    },
    {
      onSuccess() {
        Actions.clearDepositAssets(dispatch);
      },
    },
  );

  return {
    builtTx,
    submitTx,
    isSubmittingTx,
    isBuildingTx,
  };
};
