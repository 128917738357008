import { getPublicKeyAsync, utils } from "@noble/ed25519";
import { useLocalStorageState } from "@sundae/react-hooks";
import { useWalletDetails } from "@sundae/wallet-react";
import blake from "blakejs";
import { useEffect, useState } from "react";

import { Lucid } from "lucid-cardano";
import { getEnv } from "../utils/network.utils";

interface IEmulatedGummiWormData {
  address: string;
  hash: string;
  privateKey: string;
  publicKey: string;
}

export const useTempWallet = () => {
  const {
    details: { mainAddress },
  } = useWalletDetails();
  const EMULATED_DATA_SLUG = `gummiworm-emulated-data-${mainAddress}`;
  const [emulatedData, setEmulatedData] =
    useLocalStorageState<IEmulatedGummiWormData>(EMULATED_DATA_SLUG);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      if (!emulatedData?.publicKey) {
        const privateKey = utils.randomPrivateKey();
        const publicKey = await getPublicKeyAsync(privateKey);

        const hash = blake.blake2bHex(publicKey, undefined, 224 / 8);
        const network = getEnv();
        const lucidInstance = await Lucid.new(
          undefined,
          network === "mainnet" ? "Mainnet" : "Preview",
        );

        const address = lucidInstance.utils.credentialToAddress({
          type: "Key",
          hash,
        });

        setEmulatedData({
          address,
          hash,
          privateKey: Buffer.from(privateKey).toString("hex"),
          publicKey: Buffer.from(publicKey).toString("hex"),
        });
      }

      setIsLoading(false);
    })();
  }, []);

  return {
    isLoading,
    gummi: emulatedData,
  };
};
